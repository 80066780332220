/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

import styled from "styled-components"

interface RowColumnProps {
    gap?: string
    justify?: string
}

export const Container = styled.div`
    width: 100%;
    padding: 0 1rem;

    @media (max-width: 1023px) {
        padding: 1rem;
    }

    @media (min-width: 1024px) and (max-width: 1339px) {
        max-width: 960px;
        width: 960px;
    }

    @media (min-width: 1340px) {
        max-width: 1340px;
        width: 1340px;
    }

    display: flex;
    flex-direction: column;
`

export const MainContainer = styled.main`
    /* top: 6.3125rem; */
    position: relative;
    /* height: calc(100vh - 6.3125rem); */
    /* height: 100vh; */
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
`

export const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    /* position: absolute;
    top: 0;
    bottom: 0; */
    flex: 1;
`

export const EmptyFlexContainer = styled.div`
    flex: 1;
`

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
`

export const TextPageContent = styled.section`
    margin-top: 2.125rem;

    p {
        text-align: justify;
        margin: 1em 0;
    }

    a {
        color: ${({ theme }) => theme.colors.main};
    }

    ol {
        padding: 0 0 1.4375rem 1rem;
        margin-bottom: 1rem;

        li {
            margin-bottom: 10px;
        }
    }
`

export const TextPageHeader = styled.p`
    font-weight: 700;
`

export const Row = styled.div<RowColumnProps>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap};
    justify-content: ${({ justify }) => justify};
    width: 100%;
`

export const Column = styled.div<RowColumnProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ gap }) => gap};
    justify-content: ${({ justify }) => justify};
    width: 100%;
`

export const ErrorMessage = styled.div`
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.colors.crimson};
`

export const ToggleButtons = styled.div`
    display: flex;
    border-radius: ${({ theme }) => theme.radius.large};
    background-color: ${({ theme }) => theme.colors.lightGray};
    width: fit-content;
`

export const ToggleButton = styled.button`
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    line-height: 1rem;

    border: none;
    outline: none;

    border-radius: ${({ theme }) => theme.radius.small};
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.black};

    cursor: pointer;

    &.active {
        background-color: ${({ theme }) => theme.colors.main};
        color: ${({ theme }) => theme.colors.white};
        font-weight: 600;
    }
`

export const CustomForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const CustomFormItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    h2 {
        color: ${({ theme }) => theme.colors.yellow};
    }
    h3 {
        color: ${({ theme }) => theme.colors.green};
    }
    h4 {
        color: ${({ theme }) => theme.colors.blue};
    }

    > label {
        font-size: 0.875rem;
        margin-left: 4px;
    }
`

export const GHeader = styled.header`
    font-family: "Montserrat";
    font-size: 1.5rem;
    line-height: 1.8125rem;
    font-weight: 600;
`
