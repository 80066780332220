import { Route, Routes } from "react-router-dom"

import MobileMenu from "modules/components/MobileMenu/MobileMenu"
import SmallNavbar from "modules/components/SmallNavbar/SmallNavbar"
import ApplicationPage from "pages/ApplicationPage/ApplicationPage"
import AuthPage from "pages/AuthPage/AuthPage"

const App = () => {
    return (
        <>
            <SmallNavbar />
            <Routes>
                <Route path="/*" element={<ApplicationPage />} />
                <Route path="auth/*" element={<AuthPage />} />
            </Routes>
            <MobileMenu />
        </>
    )
}

export default App
