import styled from "styled-components"

import flex from "assets/icons/flex.svg"
import grid from "assets/icons/grid.svg"
import { GHeader } from "styles/Global.styled"

interface PostGridProps {
    gridColumns?: number
}

export const StyledPostList = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const PostGrid = styled.div<PostGridProps>`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.875rem;

    @media (min-width: 1339px) {
        grid-template-columns: repeat(${({ gridColumns }) => gridColumns || 4}, minmax(0, 1fr));
        gap: 2.125rem 1.875rem;
    }
`

export const PostFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const Header = styled(GHeader)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
`

export const DisplayButtons = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
`

export const Icon = styled.svg`
    width: 1.5rem;
    height: 1.5rem;
    mask-size: contain;
    mask-position: center;
    background-color: ${({ theme }) => theme.colors.gray};

    &.active {
        background-color: ${({ theme }) => theme.colors.main};
    }
`

export const GridButton = styled(Icon)`
    mask-image: url(${grid});
`

export const FlexButton = styled(Icon)`
    mask-image: url(${flex});
`

export const PostHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;

        overflow: hidden;

        font-weight: 500;
        line-height: 1.1875rem;
    }
`

export const PostDescription = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const PostPriceText = styled.div`
    font-weight: 500;
`

export const PostPrice = styled(PostPriceText)`
    &::after {
        content: "\\20B8";
        margin-left: 5px;
    }
`

export const PostDate = styled.div`
    font-size: 0.5rem;
`
