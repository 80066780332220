import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, TextPageContent, TextPageHeader } from "styles/Global.styled"

const routes = ["info", "privacy"]

const PrivacyPolicyPage = () => {
    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />

                <TextPageContent>
                    <h1>Политика конфиденциальности сервисов JBF</h1>

                    <TextPageHeader style={{ marginTop: "2rem" }}>ОПРЕДЕЛЕНИЯ</TextPageHeader>

                    <ol>
                        <li>ОБЛАСТЬ ПРИМЕНЕНИЯ и СОГЛАСИЕ</li>
                        <li>ИНФОРМАЦИЯ, КОТОРУЮ СОБИРАЕТ, ПОЛУЧАЕТ И РАЗМЕЩАЕТ КОМПАНИЯ</li>
                        <li>IP-АДРЕС, КУКИСЫ (COOKIES) и МОБИЛЬНЫЕ ИДЕНТИФИКАТОРЫ</li>
                        <li>ИСПОЛЬЗОВАНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</li>
                        <li>ОБМЕН ИНФОРМАЦИЕЙ</li>
                        <li>ПОЛЬЗОВАТЕЛЬСКИЙ КОНТРОЛЬ</li>
                        <li>БЕЗОПАСНОСТЬ</li>
                        <li>ВНЕСЕНИЕ ИЗМЕНЕНИЙ В ЭТУ ПОЛИТИКУ</li>
                    </ol>

                    <TextPageHeader>ОПРЕДЕЛЕНИЯ</TextPageHeader>

                    <p>
                        Персональные данные – сведения, относящиеся к определенному или
                        определяемому на их основании субъекту персональных данных, зафиксированные
                        на электронном, бумажном и (или) ином материальном носителе. Требования
                        Политики распространяются на любые персональные данные, обрабатываемые в
                        Компании, если иное прямо не указано конкретным положением настоящей
                        Политики.{" "}
                    </p>

                    <p>
                        Сайт (Сервис) - онлайн-платформа JBF.kz, доступная на домене JBF.kz. Далее
                        по тексту Компания (юр.лицо) и Сайт (сервис)
                    </p>

                    <p>
                        Пользователь – физическое лицо-зарегистрированный посетитель Веб-сайта, а
                        также представители бизнес-пользователей-юридических лиц, их обособленных
                        подразделений, а также индивидуальные предприниматели, которые покупают
                        услуги Компании через Веб-сайт, а также физическое лицо-пользователь
                        Веб-сайта, не имеющее Учетной записи или не авторизованное (гость).
                    </p>

                    <TextPageHeader>1. ОБЛАСТЬ ПРИМЕНЕНИЯ и СОГЛАСИЕ</TextPageHeader>

                    <p>
                        1.1 Персональные данные физических лиц-пользователей сервиса JBF.kz, а также
                        физических лиц-представителей юридических лиц, обособленных подразделений
                        юридических лиц, индивидуальных предпринимателей, которые пользуются
                        сервисом JBF.kz (представители бизнес-пользователей) собираются и
                        обрабатываются ИП Жанан, лицом, зарегистрированным по законодательству
                        Республики Казахстан, с местонахождением по адресу: г.Алматы, Наурызбайский
                        район, мкр. Шугыла, ул. С. Жунисова 8/3. Компания является собственником и
                        оператором Базы персональных данных пользователей JBF.kz.
                    </p>
                    <p>
                        1.2 Персональные данные пользователей JBF.kz обрабатываются на территории
                        Республики Казахстан.
                    </p>
                    <p>
                        1.3 Данная Политика конфиденциальности описывает установленный Компанией
                        порядок обработки персональных данных, собранных с помощью сайта JBF.kz
                        (далее – «Сайт») и связанных с ними услуг и инструментов, позволяющих
                        пользователям регистрироваться на Сайте, публиковать или просматривать в
                        режиме реального времени уже опубликованные объявления, использовать другой,
                        связанный с вышеперечисленным сервис JBF.kz. Во всех указанных случаях
                        Компания обрабатывает персональные данные пользователей исключительно в
                        рамках требований Закона Республики Казахстан «О персональных данных и их
                        защите» и Конвенции о защите лиц в связи с автоматизированной обработкой
                        персональных данных, а также внутренних документов Компании. Данная Политика
                        конфиденциальности разработана в соответствии с положениями указанных
                        нормативных актов.
                    </p>
                    <p>
                        1.4 Используя Сайт и/или иные связанные сервисы и инструменты JBF.kz,
                        Пользователь предоставляет своё согласие Компании на обработку своих данных,
                        часть которых является персональными данными, таких как имя пользователя;
                        регион проживания, адрес электронной почты, контактный телефон, другая
                        контактная информация и по желанию пользователя; ip-адреса, другие
                        коммуникационные данные пользователей; сообщения, письма, заявления,
                        передаваемые Пользователю другими Пользователями и наоборот.
                    </p>
                    <p>
                        Используя Сайт и/или иные связанные сервисы и инструменты JBF.kz,
                        Пользователь также даёт своё согласие на передачу своих персональных данных
                        третьим лицам, в том числе на передачу персональных данных за границу, в
                        любую третью страну в соответствии с данной Политикой конфиденциальности и с
                        Правилами веб-сайта JBF.kz.
                    </p>
                    <p>
                        1.5 Пользователи должны знать, что при переходе на некоторые ссылки,
                        размещённые на Сайте или в Мобильном приложении, они могут быть
                        перенаправлены к сайтам (приложениям и т.д.) других компаний за пределы
                        хостингового пространства Компании, где информация о Пользователях
                        собирается вне контроля Компании. В таком случае политики конфиденциальности
                        сайтов и/или приложений третьих лиц будут регулировать порядок обработки
                        информации, полученной от пользователей этими третьими лицами.
                    </p>

                    <TextPageHeader>
                        2. ИНФОРМАЦИЯ, КОТОРУЮ СОБИРАЕТ, ПОЛУЧАЕТ И РАЗМЕЩАЕТ КОМПАНИЯ
                    </TextPageHeader>

                    <p>
                        2.1 Информация об учетной записи: при создании Пользователем учетной записи
                        на Сайте, Компания может потребовать определенную информацию, такую как
                        адрес электронной почты или номер сотового телефона и пароль. Учетная запись
                        включает в себя такую информацию о Пользователях, как географическое
                        расположение, имя и фамилия (если предоставляются Пользователями),
                        сопутствующую информацию, в том числе фотографии, которые они могут
                        загружать в свою учетную запись, сообщения, письма, заявления, передаваемые
                        Пользователем другим Пользователям. (Учетная запись позволяет пользователям
                        связываться друг с другом, чтобы выразить заинтересованность в своих
                        предложениях).;
                    </p>

                    <p>
                        Пользователи несут ответственность за всю информацию, размещаемую ими
                        Учетных записях, которые являются общедоступными. Пользователь должен
                        внимательно рассмотреть все риски, связанные с тем, что он делает
                        определённую информацию – в частности, адрес или информацию о месте своего
                        точного расположения – общедоступной. Если Пользователь решил войти на Сайт,
                        используя службу аутентификации стороннего оператора, например информацию
                        Facebook, других социальных сетей Компания может получить дополнительный
                        профиль, или другую информацию, доступ к которой предоставлен таким третьим
                        лицом.
                    </p>

                    <p>
                        2.2 Объявления и Сделки: В рамках деятельности своего Сайта, Компания может
                        размещать информацию, в том числе личного и контактного характера,
                        необходимую для совершения сделок между покупателем и продавцом, для
                        отправки сообщений и общения Пользователями между собой, и совершения
                        платежей. Вся информация, необходимая для публикации объявлений, требуется
                        при создании Учетной записи. Пользователи несут ответственность за всю
                        информацию, размещаемую ими на Сайте. Пользователь должен внимательно
                        рассмотреть все риски, связанные с тем, что он делает определённую
                        информацию – в частности, адрес или другую информацию личного характера –
                        общедоступной.
                    </p>

                    <p>
                        2.3 Игры, Реклама и Продвижение: В рамках операций своего Сайта Компания
                        может собирать личную информацию, такую как имя и контактная информация,
                        когда Пользователи участвуют в играх, викторинах и других маркетинговых
                        акциях, организуемых на Сайте или на сторонних сайтах Компанией. В рамках
                        деятельности своего Сайта, Компания может также обрабатывать информацию,
                        связанную с эффективностью рекламных кампаний, в том числе, какие объявления
                        были просмотрены на Сайте, так и на сайтах третьих лиц.
                    </p>

                    <p>
                        2.4 Обслуживание клиентов: При обращении Пользователей в отдел обслуживания
                        клиентов, Компания может - в рамках операций своего Сайта - собирать иную
                        личную информацию, необходимую для выполнения запроса Пользователя и
                        получения обратной связи при необходимости. Объем информации, которая
                        предоставляется при обращении Пользователя, контролируется самим
                        Пользователем. Пользователь может предоставить любую информацию по своему
                        усмотрению, если Пользователь считает, что эта информация необходима для
                        ответа на его запрос. Компания может также связаться с Пользователем,
                        используя существующую контактную информацию учётной записи, предоставленную
                        для этой цели. Компания может также собирать другую информацию об общении с
                        Пользователями, например, любые запросы в службу поддержки, подаваемые
                        Пользователями, или любую обратную связь, предоставляемую Пользователями. Мы
                        можем обрабатывать отзывы в качестве данных, касающихся Пользователя-автора
                        отзыва, и Пользователя, в отношении которого был оставлен отзыв.
                    </p>

                    <p>
                        2.5 Сайт и мобильные данные: Компания может автоматически получать и
                        регистрировать на своих серверах информацию из браузера Пользователя или
                        любого устройства, включая IP-адрес, программного обеспечения и аппаратные
                        атрибуты, страницы, которые запрашивает Пользователь, мобильные
                        идентификаторы, информацию об использовании приложения, и/или информацию о
                        других используемых устройствах или информацию системного уровня. Это может
                        происходить на Сайте или на Мобильном приложении, или на сервисах третьих
                        лиц.
                    </p>

                    <p>
                        Дополнительная информация о том, как Пользователи могут контролировать и/или
                        блокировать такой сбор информации находится ниже находится в разделе 6
                        данной политики.
                    </p>

                    <p>
                        2.6 Прилагаемая информация: Компания может также добавить информацию,
                        законно полученную от деловых партнеров или третьих сторон, к существующим
                        данным Компании о своих Пользователях.
                    </p>

                    <p>
                        2.7. Информация, доступная из профайлов пользователя в многопользовательских
                        Интернет-платформах (социальных сетях): регистрируясь на Сайте, или
                        осуществляя вход на сайт с помощью служб аутентификации социальных сетей,
                        Пользователь предоставляет Компании согласие на сбор и обработку информации,
                        доступной из соответствующих профайлов в социальных сетях, а также на
                        публикацию в соответствующих социальных сетях сведений о действиях
                        Пользователя на Сайте и/или в Мобильном приложении.
                    </p>

                    <p>
                        2.8. Мы собираем информацию о рейтингах и отзывах, которые вы предоставляете
                        другим Пользователям после вашего взаимодействия с ними, а также о рейтингах
                        и отзывах, которые вы получаете от других Пользователей, в соответствии с
                        нашими Правилами веб-сайта JBF.kz.. Мы делаем это для того, чтобы измерить
                        удовлетворенность Пользователей относительно опыта транзакций, обеспечения
                        оптимального и эффективного уровня общения между Пользователями, а также для
                        проверки и предотвращения нежелательной активности и поведения в контексте
                        деятельности, осуществляемой на сервисах Компании. Эта информация позволяет
                        нам принимать необходимые и соответствующие меры для поддержания высоких
                        стандартов при оказании услуг.
                    </p>

                    <p>Информация, которую Компания не собирает и не обрабатывает:</p>

                    <p>
                        Компания не собирает и не обрабатывает персональные данные о расовом или
                        этническом происхождении, политических, религиозных или мировоззренческих
                        убеждениях, членстве в политических партиях и профессиональных союзах, и
                        подобную информацию, а также любую информацию, кроме указанной в настоящей
                        Политике конфиденциальности.
                    </p>

                    <TextPageHeader>
                        3. IP-АДРЕС, КУКИСЫ (COOKIES) и МОБИЛЬНЫЕ ИДЕНТИФИКАТОРЫ{" "}
                    </TextPageHeader>

                    <p>
                        3.1 В рамках деятельности своего сайта, Компания может собирать определенную
                        информацию с помощью таких технологий как кукисы (куки, cookies), пиксели
                        (pixels), и локального хранилища (как в Вашем браузере, или мобильном
                        устройстве).
                    </p>

                    <p>
                        3.2 КУКИС (COOKIES) - это небольшие текстовые файлы, которые хранят
                        информацию непосредственно на компьютере пользователя, его мобильном
                        телефоне или другом устройстве.
                    </p>

                    <p>
                        3.3 Пиксели (pixels) – небольшие цифровые изображения, которые являются
                        частью кодов на веб-страницах, которые позволяют другому серверу измерить
                        просматриваемость веб-страницы, и часто используются в сочетании с кукис
                        (cookies). Код отслеживает если и когда (и на какой странице) пиксель
                        загружен, чтобы указать, что пользователь взаимодействует со страницей или
                        частью страницы Сайта.
                    </p>

                    <p>
                        3.4 С помощью файлов куки (cookies), веб-сервер может сохранить, например,
                        предпочтения и настройки на компьютере Пользователя, его мобильном телефоне
                        или другом устройстве(ах), которое затем автоматически восстанавливается при
                        следующем посещении. Или, говоря иначе, файлы куки (cookies) служат, помимо
                        прочего, чтобы сделать использование Сайта более удобным, таким образом,
                        например, чтобы Пользователь не должен был повторять процесс входа в систему
                        при следующем визите. Компания использует как постоянные, так и сессионные
                        куки. Постоянные куки остаются на компьютере Пользователя в течение более
                        длительного периода времени, а сессионные куки автоматически удаляются при
                        закрытии окна браузера.
                    </p>

                    <p>
                        3.5 Компания может позволять третьим сторонам, таким как поставщики
                        рекламных и/или аналитических услуг, собирать информацию, используя эти типы
                        технологий непосредственно на веб-странице Сайта или мобильного приложения.
                        Данные, которые они собирают подлежат охране согласно действующим политикам
                        конфиденциальности этих третьих сторон.
                    </p>

                    <TextPageHeader>4. ИСПОЛЬЗОВАНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</TextPageHeader>

                    <p>
                        4.1. Для оказания своих услуг Компания может использовать информацию,
                        которую Компания собирает и размещает для следующих целей:
                    </p>

                    <p>
                        4.1.1. обеспечение обслуживания Пользователей, в том числе для создания и
                        управления учетными записями Пользователей, решения технических трудностей и
                        доступа к различным функциям, выполнения иных обязательств Компании перед
                        Пользователями;
                    </p>

                    <p>
                        4.1.2. адаптация предложений и опыта, в том числе рекламы на своих сервисах
                        или сервисах третьих лиц;
                    </p>

                    <p>
                        4.1.3. контроль общей и индивидуальной активности Пользователей, такой как
                        поиск по ключевым словам, активности по публикациям объявлений и заключений
                        сделок, а также для управления трафиком на Сайте;
                    </p>

                    <p>
                        4.1.4. связь с нашими Пользователями, в том числе по вопросам сервиса,
                        обслуживания или разрешенных маркетинговых коммуникаций через любые
                        доступные каналы связи;
                    </p>

                    <p>
                        4.1.5. проведение научно-исследовательской и аналитической активности с
                        целью улучшения нашего сервиса;
                    </p>

                    <p>
                        4.1.6. обеспечение соблюдения Правил веб-сайта JBF.kz, включая борьбу с
                        мошенничеством и оскорблениями, злоупотреблениями на Сайте;
                    </p>

                    <p>
                        4.1.7. оценка и улучшение качества услуг, работы Компании, разработка новых
                        услуг, продуктов;
                    </p>

                    <p>
                        4.1.8. обработка запросов и заявок от Пользователей в соответствии с
                        законодательством Республики Казахстан и внутренними политикам Компании;
                    </p>

                    <p>
                        4.1.9. продвижение услуг, направление рекламных сообщений, уведомление о
                        рекламных событиях, проведение конкурсов, розыгрышей, рекламных акций,
                        опросов, иных маркетинговых мероприятий, выявление победителей, доставка
                        призов;
                    </p>

                    <p>4.1.10. внедрение и реализация программы лояльности;</p>

                    <p>
                        4.1.11. проведение статистических и маркетинговых исследований, которые
                        проводятся Компанией самостоятельно и третьими лицами по ее поручению;
                    </p>

                    <p>
                        4.1.12. исполнение судебных актов, актов государственных органов или
                        должностных лиц, подлежащих исполнению в соответствии с законодательством
                        Республики Казахстан;
                    </p>

                    <p>4.1.13. показ поведенческой рекламы.</p>

                    <p>
                        4.2. Компания будет хранить Ваши персональные данные настолько долго,
                        насколько это необходимо для достижения целей, ради которых они собраны, в
                        том числе для осуществления борьбы с мошенничеством, оскорблениями на Сайте,
                        злоупотреблениями на Сайте.
                    </p>

                    <p>
                        Для определения соответствующего периода хранения Компания учитывает объем,
                        характер и чувствительность персональных данных, потенциальный риск
                        причинения вреда от несанкционированного использования или разглашения ваших
                        персональных данных, цели, ради которых Компания обрабатывает Ваши
                        персональные данные, а также можем ли Компания достичь этих целей с помощью
                        других средств, и соответствующие юридические требования.
                    </p>

                    <p>
                        Если в вашей учетной записи не было активности более 24 месяцев, мы
                        оставляем за собой право удалить вашу учетную запись, включая все данные,
                        хранящиеся в ней, что означает, что вы больше не сможете получить к ней
                        доступ и использовать ее.
                    </p>

                    <p>
                        В случае возникновения вопросов касательно периода хранения данных, а также
                        иных запросов о получении информации, об исправлении информации,
                        блокировании, уничтожении информации, пожалуйста, свяжитесь с нами через
                        Форму обратной связи или направьте сообщение лицу, ответственному за сбор и
                        обработку персональных данных по номеру +77770153464 с пометкой
                        «персональные данные».
                    </p>

                    <TextPageHeader>5. ОБМЕН ИНФОРМАЦИЕЙ</TextPageHeader>

                    <p>
                        5.1 Компания может делиться информацией, которую она собирает, с
                        аффилированными лицами (компании, действующие на основе общей собственности
                        или под общим контролем), расположенной в какой-либо третьей стране. Эти
                        компании могут только обрабатывать и использовать полученные персональные
                        данные в целях, указанных в Разделе 4 настоящей Политики конфиденциальности.
                        При этом переданные данные остаются предметом этой Политики
                        конфиденциальности.
                    </p>

                    <p>
                        5.2 Компания не предоставляет личную информацию Пользователей не
                        аффилированным лицам, за исключением случаев, когда на то есть
                        соответствующее разрешение Пользователей, или при следующих обстоятельствах.
                    </p>

                    <p>
                        5.3 Компания может использовать сторонних поставщиков услуг для
                        предоставления некоторых составляющих своих услуг, в таких случаях
                        поставщики не имеют полномочий использовать полученные при помощи Сайта
                        Компании персональные данные иначе, нежели для предоставления услуг
                        Компании, а сами персональные данные являются предметом этой Политики
                        конфиденциальности.
                    </p>

                    <p>
                        5.4 Компания оставляет за собой право в соответствии с требованиями
                        законодательства обмениваться информацией с частными лицами и
                        государственными органами в следующих целях:
                    </p>

                    <p>
                        борьбы с мошенничеством и злоупотреблениями на Сайте; расследования
                        предполагаемых нарушений закона или борьбы с любыми другими предполагаемыми
                        нарушениями Правила веб-сайта JBF.kz Пользователями.
                    </p>

                    <p>
                        5.5 Компания может предоставлять персональные данные Пользователей на
                        запросы компетентных органов только в случае, если такое требование
                        соответствует законодательству Республики Казахстан и оформлено в
                        соответствии с требованиями законодательства Республики Казахстан.
                    </p>

                    <p>
                        5.6 В соответствии с Политикой конфиденциальности, Компания обязуется не
                        передавать в аренду или продавать любые персональные данные Пользователя. В
                        случае если бизнес Компании или часть этого бизнеса будут проданы или
                        реорганизованы, и Компания передает все, или практически все свои активы
                        новому владельцу, то персональные данные пользователей могут быть переданы
                        покупателю, чтобы обеспечить непрерывность обслуживания Сайта.
                    </p>

                    <p>
                        5.7 Компания может передавать определенную обезличенную информацию (данные,
                        которые не позволяют идентифицировать каждого индивидуального Пользователя)
                        сторонним поставщикам услуг, доверенным партнерам или авторизованным
                        исследователям в целях лучшего понимания, какая реклама или услуги могут
                        заинтересовать Пользователей, улучшения общего качества и эффективности
                        услуг на Сайте или сервиса, или для обеспечения своего вклада в научные
                        исследования, которые, по мнению Компании, могут приносить большие
                        социальные блага.
                    </p>

                    <p>
                        5.8 Компания может передавать информацию, которую она собирает, третьим
                        лицам, которые предоставляют услуги Компании, с целью проведения
                        исследований, или предоставления сервисов Пользователям, при этом данные,
                        которые передаются, является предметом настоящей Политики
                        конфиденциальности, а привлеченные третьи лица не имеют права использовать
                        полученную информацию иначе, нежели для предоставления услуг Компании.
                    </p>

                    <TextPageHeader>6. ПОЛЬЗОВАТЕЛЬСКИЙ КОНТРОЛЬ</TextPageHeader>

                    <p>
                        6.1 Доступ, Исправление и Удаление: Пользователи, которые создали учётную
                        запись, или разместили объявления на Сайте, могут получать доступ,
                        исправлять или удалять информацию, которую они предоставляют. Пользователь
                        несет ответственность за точность предоставляемых данных, или сообщений на
                        Сайте. Если учетная запись Пользователя была создана через провайдера
                        идентификации (например, Facebook Connect, Вконтакте), Пользователь может
                        также отключить, или изменить данные учетной записи через настройки
                        провайдера идентификации (например, на facebook.com, vk.com). Размещенная
                        информация может быть изменена или удалена в личном кабинете пользователя
                        «Мой профиль» на веб-странице Сайта или в Мобильном приложении.
                    </p>

                    <p>
                        6.2 Выбор внешних лиц: некоторые внешние субъекты, действующие на Сайте,
                        например, Google Adwords позволяют Пользователям отозвать свое согласие на
                        сбор и использование своих данных для рекламы на основе активности
                        Пользователей. Для получения более подробной информации и возможности
                        выбора, пожалуйста, посетите http://www.networkadvertising.org.
                    </p>

                    <p>
                        6.3 Файлы Куки (Cookies): Большинство основных компьютерных (десктопных) и
                        мобильных веб-браузеров (например, Safari, Firefox, Internet Explorer,
                        Chrome, Opera) обеспечивают элементы управления, которые позволяют
                        Пользователю ограничить или заблокировать установку файлов Куки (Cookies) на
                        ваших системах. Обратите внимание, что отключение Куки файлов (Cookies) в
                        отношении доменов первой категории (посещаемых сайтов) и других доменов
                        (сайтов, связанных с посещаемыми) может привести в некоторых случаях к
                        ограниченной функциональности этих веб-сайтов.
                    </p>

                    <p>
                        6.4 Другие права Пользователей в связи с обработкой их персональных данных
                        Компанией:
                    </p>

                    <p>
                        6.4.1 знать о наличии у Компании, а также третьего лица своих персональных
                        данных, а также получать информацию, содержащую: подтверждение факта, цели,
                        источников, способов сбора и обработки персональных данных; перечень
                        персональных данных; сроки обработки персональных данных, в том числе сроки
                        их хранения (право на получение информации);
                    </p>

                    <p>
                        6.4.2. требовать от Компании изменения и дополнения своих персональных
                        данных при наличии оснований, подтвержденных соответствующими документами
                        (право на исправление);
                    </p>

                    <p>
                        6.4.3. требовать от Компании, а также третьего лица блокирования своих
                        персональных данных в случае наличия информации о нарушении условий сбора,
                        обработки персональных данных (право на блокирование);
                    </p>

                    <p>
                        6.4.4. требовать от Компании, а также третьего лица уничтожения своих
                        персональных данных, сбор и обработка которых произведены с нарушением
                        законодательства Республики Казахстан, а также в иных случаях, установленных
                        нормативными правовыми актами Республики Казахстан (право на уничтожение);
                    </p>

                    <p>
                        6.4.5. отозвать согласие на сбор, обработку персональных данных, кроме
                        случаев, предусмотренных пунктом 2 статьи 8 Закона Республики Казахстан «О
                        персональных данных и их защите» (право на отзыв согласия);
                    </p>

                    <p>
                        6.4.6. предъявлять мотивированное требование об изменении или уничтожение
                        персональных данных владельцем, если эти данные обрабатываются незаконно или
                        являются недостоверными;
                    </p>

                    <p>
                        6.4.7. дать согласие (отказать) собственнику и (или) оператору на
                        распространение своих персональных данных в общедоступных источниках
                        персональных данных;
                    </p>

                    <p>
                        6.4.8. на защиту своих прав и законных интересов, в том числе возмещение
                        морального и материального вреда;
                    </p>

                    <p>
                        6.4.9. на осуществление иных прав, предусмотренных настоящим законами
                        Республики Казахстан.
                    </p>

                    <TextPageHeader>7. БЕЗОПАСНОСТЬ</TextPageHeader>

                    <p>
                        7.1. Вся информация, которую мы собираем в разумных пределах защищена
                        техническими средствами и процедурами обеспечения безопасности в целях
                        предотвращения несанкционированного доступа или использования данных.
                        Аффилированные с Компанией лица, надёжные партнеры и сторонние поставщики
                        услуг обязуются использовать полученную от Компании информацию в
                        соответствии с нашими требованиями к безопасности и этой Политикой
                        конфиденциальности.
                    </p>

                    <TextPageHeader>8. ВНЕСЕНИЕ ИЗМЕНЕНИЙ В ЭТУ ПОЛИТИКУ</TextPageHeader>

                    <p>
                        8.1. Эта политика конфиденциальности была последний раз обновлена 06.07.2022
                        г. Компания может обновлять эту политику конфиденциальности время от
                        времени, новая редакция Политики конфиденциальности вступает в силу с
                        момента ее размещения в сети Интернет по указанному в данном пункте адресу,
                        если иное не предусмотрено новой редакцией Политики конфиденциальности.
                        Действующая редакция Политики конфиденциальности всегда находится на
                        странице по адресу.
                    </p>

                    <p>
                        8.2. В случае если Компанией были внесены любые изменения в Политику
                        Конфиденциальности, с которыми Пользователь не согласен, он обязан
                        прекратить использование сервисов Веб-сайта и Мобильного приложения. Факт не
                        прекращения использования Сайта и (или) Мобильного приложения является
                        подтверждением согласия и принятия Пользователем действующей редакции
                        Политики конфиденциальности, а также иных документов Компании.
                    </p>
                </TextPageContent>
            </Container>
        </Page>
    )
}

export default PrivacyPolicyPage
