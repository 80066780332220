import moment from "moment"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { UserAppealType } from "types/common"
import Checkbox from "ui/Checkbox/Checkbox"

import {
    MessageContent,
    MessageDate,
    MessageIcons,
    MessageRow,
    MessageText,
    PostTitle,
    StarIcon,
    StyledMobileMessage,
} from "./MobileMessage.styled"

import { AppealsListContext } from "../../AppealsList"

const MobileMessage = () => {
    const appealsCtx = useContext(AppealsListContext)
    const navigate = useNavigate()

    const handleMessageClick = (event: React.MouseEvent, appeal: UserAppealType) => {
        navigate("/cabinet/appeals/message", { state: { appeal, id: appeal.order.id } })
        event.stopPropagation()
    }

    return (
        <StyledMobileMessage>
            {appealsCtx.userAppealList.map((appeal: UserAppealType, index: number) => (
                <MessageRow key={index}>
                    <Checkbox />

                    <MessageContent onClick={(event) => handleMessageClick(event, appeal)}>
                        <div>{appeal.order.user_name}</div>
                        <PostTitle>{appeal.post.title}</PostTitle>
                        <MessageText>{appeal.order.comment}</MessageText>
                    </MessageContent>

                    <MessageIcons>
                        <StarIcon
                            liked={appealsCtx.favouriteIDS.includes(appeal.order.id)}
                            onClick={(event) =>
                                appealsCtx.handleFavourite(
                                    appeal.order.id,
                                    !appealsCtx.favouriteIDS.includes(appeal.order.id),
                                    event
                                )
                            }
                        />
                        <MessageDate>
                            <div>{moment(appeal.order.date).locale("ru").format("D MMMM")}</div>
                            <div>{moment(appeal.order.date).locale("ru").format("HH:mm")}</div>
                        </MessageDate>
                    </MessageIcons>
                </MessageRow>
            ))}
        </StyledMobileMessage>
    )
}

export default MobileMessage
