import { useNavigate } from "react-router-dom"

import mobileAppBanner from "assets/banner/mobileAppBanner.png"
import useMediaQuery from "modules/Core/hooks/useMediaQuery"
import { Column, Container, GHeader } from "styles/Global.styled"

import { Banner, BannerText, Button, Content, MarketBanner } from "./BottomBanner.styled"

import MarketIcons from "../MarketIcons/MarketIcons"

interface BottomBannerProps {
    type?: string
}

const BottomBanner: React.FC<BottomBannerProps> = ({ type }) => {
    const navigate = useNavigate()
    const { isMobileDevice } = useMediaQuery()
    return (
        <>
            {isMobileDevice ? (
                <Banner type={type}>
                    Начните работать с нами прямо сейчас
                    <Button onClick={() => navigate("/auth", { state: { authType: "signOn" } })}>
                        Регистрация
                    </Button>
                </Banner>
            ) : (
                <Container>
                    <MarketBanner>
                        <Content>
                            <Column gap="1rem">
                                <GHeader>Резервируй участие</GHeader>
                                <BannerText>
                                    С приложением всё будет быстрее и удобнее
                                    <br />
                                    <br />
                                    Мы будем доступны на всех платформах
                                </BannerText>
                            </Column>
                            <MarketIcons />
                        </Content>

                        <img src={mobileAppBanner} />
                    </MarketBanner>
                </Container>
            )}
        </>
    )
}

export default BottomBanner
