import moment from "moment"
import { Link } from "react-router-dom"

import Like from "modules/components/Like/Like"
import {
    PostHeader,
    PostDescription,
    PostPrice,
    PostPriceText,
    PostDate,
} from "modules/components/PostList/PostList.styled"
import useMainContext from "modules/Core/hooks/useMainContext"
import { PostType } from "types/common"

import { StyledPost } from "./Post.styled"

import PostImage from "../PostImage/PostImage"

interface PostProps {
    details: PostType
}

const Post: React.FC<PostProps> = ({ details }) => {
    const { image, title, id, description, price, date } = details

    const { favouritePosts } = useMainContext()
    const isPostLiked = favouritePosts.some((post: PostType) => (post?.post_id || post?.id) === id)

    return (
        <StyledPost>
            <PostImage id={id} image={image} />
            <PostHeader>
                <Link to={`/catalogue/post/${id}`} target="_blank" rel="noopener noreferrer">
                    {title}
                </Link>
                <Like postID={id} isLiked={isPostLiked} />
            </PostHeader>
            <PostDescription>{description}</PostDescription>

            {price === 0 ? (
                <PostPriceText>Цена договорная</PostPriceText>
            ) : (
                <PostPrice>{price.toLocaleString()}</PostPrice>
            )}

            <PostDate>{moment(date).format("DD.MM.YYYY")}</PostDate>
        </StyledPost>
    )
}

export default Post
