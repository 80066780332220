import { createContext } from "react"

import { POST } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import useMediaQuery from "modules/Core/hooks/useMediaQuery"
import { GHeader } from "styles/Global.styled"

import { StyledAppealsList } from "./AppealsList.styled"
import DesktopMessage from "./components/DesktopMessage/DesktopMessage"
import MobileMessage from "./components/MobileMessage/MobileMessage"

import useSelectedMessages from "../../hooks/useSelectedMessages"
import useUserAppealList from "../../hooks/useUserAppealList"
import NoDataFound from "../NoDataFound/NoDataFound"

interface AppealsListProps {
    appealType: string
}

const APPEAL_HEADERS: Record<string, string> = {
    last: "Входящие сообщения",
    send: "Отправленые сообщения",
    archive: "Архивные сообщения",
}

//eslint-disable-next-line
export const AppealsListContext = createContext<any>({})

const AppealsList: React.FC<AppealsListProps> = ({ appealType }) => {
    const { userAppealList, setUserAppealList, favouriteIDS, setFavouriteIDS } =
        useUserAppealList(appealType)
    const { selectedMessagesID, handleSelect, handleSelectAll } = useSelectedMessages()

    const { isMobileDevice } = useMediaQuery()
    const headers = useAuthorizationHeader()

    const handleFavourite = async (
        orderID: number,
        isFavourite: boolean,
        event: React.MouseEvent
    ) => {
        event.stopPropagation()

        const request = {
            order_id: orderID,
            is_favourite: isFavourite,
        }

        try {
            await POST("/api/service/order/favourite/", request, headers)

            isFavourite
                ? setFavouriteIDS((current) => [...current, orderID])
                : setFavouriteIDS((current) => current.filter((item) => item !== orderID))
        } catch (err) {
            console.error(err)
        }
    }

    const handleArchive = async (orderIDS: number[], event: React.MouseEvent) => {
        event.stopPropagation()

        if (window.confirm("Вы действительно хотите удалить данное сообщение?")) {
            try {
                await POST(
                    "/api/service/orders/archive",
                    { order_ids: orderIDS.join(",") },
                    headers
                )
                setUserAppealList((current) =>
                    current.filter((item) => !orderIDS.includes(item.order.id))
                )
            } catch (err) {
                console.error(err)
            }
        }
    }

    const handleDelete = async (orderIDS: number[], event: React.MouseEvent) => {
        event.stopPropagation()

        if (window.confirm("Вы действительно хотите удалить данное сообщение?")) {
            try {
                await POST("/api/service/order/delete/", { order_ids: orderIDS.join(",") }, headers)
                setUserAppealList((current) =>
                    current.filter((item) => !orderIDS.includes(item.order.id))
                )
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <StyledAppealsList>
            <GHeader>{APPEAL_HEADERS[appealType]}</GHeader>

            {userAppealList.length == 0 && <NoDataFound />}

            <AppealsListContext.Provider
                value={{
                    userAppealList,
                    handleArchive,
                    handleFavourite,
                    favouriteIDS,
                    selectedMessagesID,
                    handleSelect,
                    handleSelectAll,
                    handleDelete,
                }}
            >
                {userAppealList.length > 0 &&
                    (isMobileDevice ? <MobileMessage /> : <DesktopMessage />)}
            </AppealsListContext.Provider>
        </StyledAppealsList>
    )
}

export default AppealsList
