import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import useMainContext from "modules/Core/hooks/useMainContext"
import { SliderType } from "types/common"
import { Button } from "ui/Button/Button.styled"

import {
    Carousel,
    CarouselContent,
    CarouselContentAlt,
    CarouselController,
    CarouselControllers,
    CarouselPicture,
    Header,
} from "./MainMenuCarousel.styled"

function fGetBgColor(index: number) {
    const indexArr = [
        [0, 3],
        [1, 4],
        [2, 5],
    ]
    if (indexArr[1].includes(index)) return "#74ADDE"
    else if (indexArr[2].includes(index)) return "#F8993C"
    else return ""
}

const MainMenuCarousel = () => {
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const { sliderList } = useMainContext()

    const handleNavigation = (id: number) => {
        navigate(`/catalogue/${id}`)
    }
    //eslint-disable-next-line
    let timer: any

    const updateCount = () => {
        timer =
            !timer &&
            setInterval(() => {
                setActiveIndex((prevIndex) => prevIndex + 1)
            }, 8000)

        if (activeIndex === sliderList?.length) {
            setActiveIndex(0)
        }
    }

    useEffect(() => {
        updateCount()

        return () => clearInterval(timer)
    }, [activeIndex]) // new

    // useEffect(() => {
    //     sliderList?.forEach((picture: SliderType) => {
    //         new Image().src = picture.image
    //     })

    //     const interval = setInterval(() => {
    //         if (activeIndex === sliderList?.length - 1) {
    //             setActiveIndex(0)
    //         } else {
    //             setActiveIndex((current) => current + 1)
    //         }
    //     }, 8000)

    //     return () => clearInterval(interval)
    // }, [])

    return (
        <Carousel>
            {sliderList?.length > 0 && (
                <CarouselPicture
                    url={sliderList[activeIndex]?.image}
                    caption={sliderList[activeIndex]?.name}
                    index={activeIndex}
                    backgroundColor={fGetBgColor(activeIndex)}
                >
                    {!sliderList[activeIndex]?.text ? (
                        <CarouselContent>
                            <Header>{sliderList[activeIndex]?.name}</Header>
                            <Button onClick={() => handleNavigation(sliderList[activeIndex]?.link)}>
                                Посмотреть
                            </Button>
                        </CarouselContent>
                    ) : (
                        <CarouselContentAlt>
                            <header>{sliderList[activeIndex]?.name}</header>
                            <div>{sliderList[activeIndex]?.text}</div>
                            <Button onClick={() => handleNavigation(sliderList[activeIndex]?.link)}>
                                Посмотреть
                            </Button>
                        </CarouselContentAlt>
                    )}
                </CarouselPicture>
            )}
            <CarouselControllers>
                {sliderList?.map((controller: SliderType, index: number) => (
                    <CarouselController
                        onClick={() => setActiveIndex(index)}
                        key={index}
                        color={`${activeIndex === index ? "main" : ""}`}
                    />
                ))}
            </CarouselControllers>
        </Carousel>
    )
}

export default MainMenuCarousel
