/*eslint-disable*/
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useState } from "react"
import { toast } from "react-toastify"
import * as Yup from "yup"

import { POST_FORM } from "modules/Core/api/REST"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import {
    AdminForm,
    AdminFormButtons,
    AdminFormItem,
    AdminImagesGrid,
    AdminPostMainImage,
    PostTitle,
} from "pages/AdminPanelPage/modules/helpers/components/AdminForm/AdminForm.styled"
import Required from "pages/AdminPanelPage/modules/helpers/components/Required/Required"
import { PostType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { renderError } from "utils/helpers"
import {
    InputComponent,
    SelectСomponent,
    TextareaComponent,
} from "utils/helpers/components/FormikField"

import { postStatusOptions } from "../utils"
import { Column } from "styles/Global.styled"
interface EditPostProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    postEdit?: PostType
}

interface FormFields {
    description: string
    price: string | number
    email: string
    status: number | string
}

const EditPostForm: React.FC<EditPostProps> = ({ setCurrentState, postEdit }) => {
    const headers = useAuthorizationHeader()

    const [mainPreview, setMainPreview] = useState(postEdit?.imgs[0]?.img)

    const handleSubmit = async (values: FormFields) => {
        console.log("submit", values.status)

        try {
            const formData = new FormData()

            formData.append("status", String(values.status))

            const { data } = await POST_FORM("/api/admin/posts/" + postEdit?.id, formData, headers)

            if (data.ok === 1) {
                toast.success("Объявление успешно обновлено!")
            } else {
                throw new Error(JSON.stringify(data?.detail))
            }
        } catch (error) {
            toast.error("Произошла ошибка" + error)
        }
    }

    const initialValues = {
        description: postEdit?.description || "",
        price: postEdit?.price || "",
        status: postEdit?.status?.key || 2,
        email: postEdit?.user?.email || "",
    }

    return (
        <>
            <PostTitle>{postEdit?.title}</PostTitle>
            <AdminForm>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    enableReinitialize={true}
                >
                    <Form>
                        <AdminFormItem>
                            <label htmlFor="">Пользователь (email):</label>
                            <div>
                                <Field name="email" disabled component={InputComponent} />
                                <ErrorMessage name="email" render={renderError} />
                            </div>
                        </AdminFormItem>
                        <AdminFormItem>
                            <label htmlFor="">Описание:</label>
                            <div>
                                <Field name="description" disabled component={TextareaComponent} />
                                <ErrorMessage name="description" render={renderError} />
                            </div>
                        </AdminFormItem>
                        <AdminFormItem>
                            <label htmlFor="">Цена:</label>
                            <div>
                                <Field name="price" disabled component={InputComponent} />
                                <ErrorMessage name="price" render={renderError} />
                            </div>
                        </AdminFormItem>
                        <AdminFormItem>
                            <label htmlFor="">
                                Статус объявления: <Required />
                            </label>
                            <div>
                                <Field
                                    name="status"
                                    component={SelectСomponent}
                                    placeholder="Выберите статус"
                                    options={postStatusOptions}
                                />
                                <ErrorMessage name="status" render={renderError} />
                            </div>
                        </AdminFormItem>
                        {postEdit?.imgs && postEdit?.imgs?.length > 0 && (
                            <AdminFormItem>
                                <label htmlFor="">Фото:</label>
                                <Column>
                                    <AdminPostMainImage
                                        style={{ backgroundImage: `url("${mainPreview}")` }}
                                    />
                                    <AdminImagesGrid>
                                        {postEdit?.imgs.map((image) => (
                                            <div
                                                key={image.id}
                                                onClick={() => setMainPreview(image.img)}
                                                style={{ backgroundImage: `url("${image.img}")` }}
                                            />
                                        ))}
                                    </AdminImagesGrid>
                                </Column>
                            </AdminFormItem>
                        )}
                        <AdminFormButtons>
                            <Button
                                type="button"
                                btnColor="darkestGray"
                                onClick={() => setCurrentState("list")}
                            >
                                Вернуться к списку
                            </Button>
                            <Button type="submit">Сохранить</Button>
                        </AdminFormButtons>
                    </Form>
                </Formik>
            </AdminForm>
        </>
    )
}

export default EditPostForm
