import appstore from "assets/appLinkIcons/appstore.svg"
import googleplay from "assets/appLinkIcons/googleplay.svg"
import huawei from "assets/appLinkIcons/huawei.svg"

import { MarketIcon, StyledMarketIcons } from "./MarketIcons.styled"

const MarketIcons = () => {
    const handleClick = () => {
        window.open("https://wa.me/77471568377", "_blank")
    }

    return (
        <StyledMarketIcons onClick={handleClick}>
            <MarketIcon url={googleplay} />
            <MarketIcon url={appstore} />
            <MarketIcon url={huawei} />
        </StyledMarketIcons>
    )
}

export default MarketIcons
