import { Formik, Form, Field, ErrorMessage } from "formik"
import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

// import { POST } from "modules/Core/api/REST"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import { AuthContent, FormItem, TextContainer } from "pages/AuthPage/AuthPage.styled"
import { Button } from "ui/Button/Button.styled"
import { StyledLink } from "ui/Link/Link.styled"
import { renderError } from "utils/helpers"
import { InputComponent } from "utils/helpers/components/FormikField"

import { AuthFormContext } from "../../context/AuthFormContext"
import Password from "../Password/Password"

const initialValues = { email: "", password: "" }
const validationSchema = Yup.object({
    email: Yup.string()
        .email("Введите корректный адрес эл.почты")
        .required("Поле E-mail обязательно для заполнения!"),
    password: Yup.string().required("Введите пароль"),
})

const SignInForm = () => {
    const authContext = useContext(AuthFormContext)

    const { setAuth } = useAuth()

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const handleSignIn = async (values: Record<string, unknown>) => {
        const response = await POST("/api/accounts/login/", values)

        if (response && response.data) {
            const { data } = response.data

            const accessToken = data?.token

            if (accessToken) {
                const { user } = data
                const { user_type: role } = user

                console.log(role)

                localStorage.setItem(
                    "auth_user_credentials",
                    JSON.stringify({ user, role, accessToken })
                )
                setAuth({ user, role, accessToken })
                navigate(from, { replace: true })
            } else {
                authContext?.setErrorMessage("Неверный E-mail или пароль!")
            }
        } else {
            authContext?.setErrorMessage("Непредвиденная ошибка, попробуйте позже!")
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignIn}
            validateOnBlur={false}
        >
            <Form>
                <AuthContent>
                    <FormItem>
                        <label htmlFor="">E-mail</label>
                        <Field name="email" component={InputComponent} />
                        <ErrorMessage name="email" render={renderError} />
                    </FormItem>

                    <FormItem>
                        <label htmlFor="">Пароль</label>
                        <Password>
                            <Field
                                name="password"
                                type={authContext?.showPassword ? "text" : "password"}
                                component={InputComponent}
                            />
                        </Password>
                        <ErrorMessage name="password" render={renderError} />
                    </FormItem>
                </AuthContent>

                <TextContainer>
                    <StyledLink to="#" onClick={() => authContext?.setForgetPassword(true)}>
                        Забыли пароль?
                    </StyledLink>
                </TextContainer>

                <Button type="submit" style={{ marginTop: "1rem" }}>
                    Войти
                </Button>

                <TextContainer align="center">
                    <StyledLink to="/terms">Публичная оферта</StyledLink> и важная информация о
                    безопасности данных
                </TextContainer>
            </Form>
        </Formik>
    )
}

export default SignInForm
