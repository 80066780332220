import Breadcrumbs from "modules/components/Breadcrumbs/Breadcrumbs"
import { Container, Page, TextPageContent, TextPageHeader } from "styles/Global.styled"

const routes = ["info", "terms"]

const TermsOfUsePage = () => {
    return (
        <Page>
            <Container>
                <Breadcrumbs routes={routes} />

                <TextPageContent>
                    <h1>Пользовательское соглашение</h1>

                    <TextPageHeader style={{ marginTop: "2rem" }}>Оглавление</TextPageHeader>

                    <ol>
                        <li>ОБЩИЕ ПОЛОЖЕНИЯ</li>
                        <li>РАЗМЕЩЕНИЕ ОБЪЯВЛЕНИЙ</li>
                        <li>
                            УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ И СОГЛАСИЕ НА НЕЕ
                        </li>
                        <li>СВЕДЕНИЯ, ПРЕДОСТАВЛЯЕМЫЕ ПОЛЬЗОВАТЕЛЕМ</li>
                        <li>ПРИЧИНЫ УДАЛЕНИЯ ОБЪЯВЛЕНИЙ</li>
                        <li>ПРАВА И ОБЯЗАННОСТИ СТОРОН</li>
                        <li>ОПЛАТА УСЛУГ</li>
                        <li>ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ КОМПАНИИ</li>
                        <li>СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ И ПРЕКРАЩЕНИЕ ОКАЗАНИЯ УСЛУГ САЙТА</li>
                        <li>ВНЕСЕНИЕ ИЗМЕНЕНИЙ В СОГЛАШЕНИЕ</li>
                        <li>ПРОЧИЕ УСЛОВИЯ</li>
                    </ol>

                    <TextPageHeader>1. ОБЩИЕ ПОЛОЖЕНИЯ </TextPageHeader>

                    <p>
                        1.1. ИП ЖАНАН, (далее Исполнитель и/или Компания) публикует данный Публичный
                        договор (Соглашение и/или Оферта) об оказании услуг на инте930ет-сайте
                        Исполнителя jbf.kz.
                    </p>

                    <p>
                        1.2. В соответствии со статьей 387 Гражданского Кодекса Республики Казахстан
                        (ГК Республики Казахстан) данное Соглашение является публичным договором, и
                        в случае принятия (акцепта) изложенных ниже условий любое дееспособное
                        физическое или юридическое лицо (далее Пользователь) обязуется выполнять
                        условия этого Соглашения.
                    </p>

                    <p>
                        1.3. В настоящей оферте, если контекст не требует иного, нижеприведенные
                        термины имеют следующие значения:
                    </p>

                    <p>
                        Оферта – публичное предложение Исполнителя, адресованное любому физическому
                        и/или юридическому лицу, заключить с ним Публичный договор об оказании услуг
                        на условиях, содержащихся в данном Соглашении, включая все его приложения;
                        Акцепт – полное принятие Пользователем условий Соглашения; Исполнитель – ИП
                        ЖАНАН, юридический адрес: г.Алматы, Наурызбайский район, мкр. Шугыла, ул. С.
                        Жунисова 8/3; БИН 64012940752; Сайт – https://www.jbf.kz – интернет-сайт,
                        администрируемый Компанией и представляющий собой коммуникационную платформу
                        для размещения временных классифицируемых объявлений (далее по тексту –
                        Сайт); Пользователь – любое дееспособное физическое или юридическое лицо,
                        принявшее условия данного Соглашения и пользующееся услугами Компании;
                        Бизнес-пользователь – пользователь, использующий Сайт в бизнес-целях, и
                        соответствует хотя бы одному из указанных в настоящем Соглашении признаков.
                        Пользователь может считаться Компанией Бизнес-пользователем независимо от
                        того, зарегистрирован ли такой пользователь как субъект предпринимательской
                        деятельности в установленном законодательством порядке, или же осуществляет
                        такую деятельность без соответствующей регистрации; Товар - любой
                        материальный и нематериальный объект; Услуга – любая операция, не являющаяся
                        поставкой товаров, связанная с предоставлением сервиса, который потребляется
                        в процессе совершения определенного действия или осуществления определенной
                        деятельности, для удовлетворения личных потребностей заказчика;
                        Услуги/сервис JBF – любые платные и бесплатные сервисы, оказываемые
                        Исполнителем при помощи Сайта (например, в том числе, но не исключительно,
                        всех его возможностей, текста, данных, информации, программного обеспечения,
                        графиков или фотографий, рисунков и т.д. и т.п.), а также любых других
                        услуг, предоставляемых Компанией с помощью сервисов Сайта; Учетная
                        запись/аккаунт – электронный кабинет Пользователя в функциональной системе
                        Сайта, с помощью которого он может управлять своими объявлениями на Сайте;
                        Регистрация – принятие Пользователем оферты на заключение данного Соглашения
                        и процедура, в ходе которой Пользователь посредством заполнения
                        соответствующих форм Сайта предоставляет необходимую информацию для
                        использования сервисов Сайта. Регистрация считается завершенной только в
                        случае успешного прохождения всех ее этапов в соответствии с опубликованными
                        на Сайте инструкциями; Персональные данные - это сведения или совокупность
                        сведений о физическом лице, которое при их помощи идентифицировано или может
                        быть конкретно идентифицировано; SMS-верификация - это проверка
                        Пользователя, которая осуществляется путем ввода в соответствующее поле на
                        странице верификации специального кода верификации, присланного Компанией в
                        SMS-сообщении на номер, указанный Пользователем на странице верификации.
                        Размещение - это действие Пользователя - публикация, или активация одного
                        объявления. Размещением также является изменение существующего объявления,
                        если такое изменение предполагает изменение, и/или добавление товара,
                        изменение существенных характеристик товара, изменение региона.
                    </p>

                    <p>
                        1.4. Если Пользователь не согласен с Соглашением полностью или частично,
                        Исполнитель просит его покинуть данный сайт и/или удалить приложение с
                        устройства. Настоящие условия регулируют использование Пользователем Сайтов
                        и услуг JBF.kz. Использование Услуг JBF.kz означает, что Пользователь
                        ознакомлен с настоящим Соглашением, понимает и принимает его условия. Права
                        и обязанности Сторон, правила пользования Сайтами могут содержаться также в
                        статьях Центра поддержки пользователей, материалах, размещенных на Сайте
                        (или ссылки на которые размещены на Сайте). Такие статьи, материалы являются
                        неотъемлемой частью Соглашения.
                    </p>

                    <p>
                        1.5. Начиная использовать какой-либо сервис JBF.kz, либо пройдя процедуру
                        регистрации, Пользователь считается принявшим условия Соглашения в полном
                        объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с
                        какими-либо из положений настоящего Соглашения, Пользователь не в праве
                        использовать сервисы JBF.kz.
                    </p>

                    <p>
                        1.6. Настоящим Компания предлагает Пользователям сети Интернет использовать
                        свои сервисы на условиях, изложенных в настоящем Соглашении.
                    </p>

                    <p>
                        1.7. Компания предлагает Пользователю услуги по использованию Сайта для
                        размещения информации о товарах (услугах) c целью, в том числе, но не
                        исключительно, последующей покупки или продажи различных товаров и услуг
                        другими Пользователями.
                    </p>

                    <p>
                        1.8. Все сделки заключаются между Пользователями напрямую. Таким образом,
                        Компания не является участником сделок Пользователей, а лишь предоставляет
                        коммуникационную торговую платформу для размещения объявлений.
                    </p>

                    <p>
                        1.9. Пользователь имеет право обратиться за защитой своих нарушенных прав и
                        законных интересов в Комитет по защите прав потребителей Министерства
                        торговли и интеграции Республики Казахстан, или в соответствующий суд
                        согласно установленным правилам подведомственности и подсудности.
                        Официальный сайт Комитета по защите прав потребителей МИТ РК -
                        https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru
                    </p>

                    <TextPageHeader>2. РАЗМЕЩЕНИЕ ОБЪЯВЛЕНИЙ</TextPageHeader>

                    <p>
                        2.1. Пользователь получает право размещать объявления на Сайтах после
                        прохождения Email-верификации. В случае удаления Пользователем учетной
                        записи, email, который ранее использовался для Email-верификации, может быть
                        использован Пользователем повторно не ранее 30 дней с даты удаления Учетной
                        записи. В случае блокировки Компанией Учетной записи, телефонный номер,
                        который использовался для Email-верификации, может быть использован повторно
                        не ранее 1 года с даты блокировки соответствующей Учетной записи.
                    </p>

                    <p>
                        2.2. Пользователь также имеет право зарегистрироваться на Сайте с целью
                        получения дополнительных сервисов, заполнив формуляр с указанием
                        действительного электронного адреса, к которому имеет доступ только
                        Пользователь, выбранного пароля, а также других данных, необходимых для
                        регистрации. После этого Пользователь получает электронное письмо с
                        подтверждением регистрации, содержащее ссылку, переход по которой необходим
                        для завершения регистрации.
                    </p>

                    <p>
                        2.3. Пользователь имеет право создать учётную запись и войти на Сайт,
                        используя данные учетной записи в Facebook через подключение Facebook
                        Connect. Пользователь, который не зарегистрирован на Сайте, для регистрации
                        с помощью Facebook, должен ввести данные своей учетной записи (логин и
                        пароль) Facebook по запросу системы, после чего сможет воспользоваться
                        сервисами Сайта. Регистрируясь на Сайте через через подключение Facebook
                        Connect, Пользователь предоставляет Компании дополнительные персональные
                        данные. Детальная информация о персональных данных, которые предоставляются
                        Компании и обрабатываются Компанией - в Приложении №1 к Соглашению.
                    </p>

                    <p>
                        2.4. Использование возможностей и сервисов Сайта, как зарегистрированными
                        так и незарегистрированными Пользователями означает принятие обязательств
                        следовать правилам и инструкциям по пользованию услугами JBF.kz.
                    </p>

                    <p>
                        2.5. Пользователь несет ответственность за все действия с использованием его
                        электронного адреса и пароля для входа на Сайт. Пользователь имеет право
                        пользоваться сервисами Сайта только при помощи собственного электронного
                        адреса и пароля.
                    </p>

                    <p>
                        2.6. Пользователь обязуется сохранять конфиденциальность переданного ему
                        пароля и не раскрывать его третьим лицам.
                    </p>

                    <p>
                        2.7. Пользователь обязан немедленно изменить данные для входа на Сайт, если
                        у него есть причины подозревать, что его электронный адреса и пароль,
                        используемые для входа на Сайт, были раскрыты или могут быть использованы
                        третьими лицами.
                    </p>

                    <p>
                        2.8. Пользователь, размещающий объявления о продаже товаров или услуг на
                        Сайте, обязуется разместить информацию о них в соответствии настоящим
                        Соглашением и инструкциями, представленными на Сайте, и предоставить точную
                        и полную информацию о товаре или услугах и условиях их продажи. Размещая
                        информацию о товаре или услуге, Пользователь подтверждает, что он имеет
                        право продавать этот товар или оказывать данную услугу в соответствии с
                        требованиями законодательства стран, в которых они реализуются.
                    </p>

                    <p>
                        2.9. Пользователь гарантирует, что предлагаемые им товары/услуги
                        соответствуют нормам качества, установленным законодательством стран, для
                        которых они реализуются и свободны от притязаний третьих лиц.
                    </p>

                    <p>
                        2.10. Пользователь гарантирует, что предлагаемые им услуги, в случае если
                        оказание их требует специального разрешения, будут осуществлены в
                        соответствии с требованиями законодательства стран, специальные органы
                        которых будут уполномочены осуществлять надзор за такой деятельностью
                        пользователя.
                    </p>

                    <p>
                        2.11. Пользователь обязан тщательно проверить всю информацию о товарах и
                        услугах, размещенных им на Сайте, и, в случае обнаружения неверной
                        информации, добавить необходимые сведения в описание товара или услуги. Если
                        это невозможно, исправить неверную информацию, аннулировав объявление и
                        повторно разместив информацию о товаре или услуге.
                    </p>

                    <p>
                        2.12. Цена товара, услуги, работы указывается Пользователем в национальной
                        валюте Республики Казахстан (тенге), допускается также указание эквивалента
                        стоимости товара, услуги, работы в других валютах. Условия доставки должны
                        включаться в описание товара, а условия оказания услуг в описание услуги.
                        Условия продажи товара и оказания услуг, составленные Пользователем, не
                        должны противоречить настоящему Соглашению и действующему законодательству
                        стран, для которых они реализуются.
                    </p>

                    <p>
                        2.13. Пользователь обязуется не оказывать активную поддержку и не
                        распространять информацию об услугах, предоставляемых конкурентами
                        Исполнителя, как-то, но не исключительно:
                    </p>

                    <p>
                        Информацию о других досках объявлений, торговых площадках,
                        интернет-аукционах и/или интернет-магазинах; Интернет-ресурсах, предлагающих
                        товары и услуги, запрещенные к продаже на Сайте.
                    </p>

                    <p>
                        2.14. Компания имеет право переместить, завершить или продлить срок
                        демонстрации товара или услуги Пользователя по техническим причинам,
                        находящимся под контролем или вне контроля Компании. Сайт имеет право
                        прекратить демонстрацию объявления, если Пользователь зарегистрировал товар
                        или услугу, с нарушением условий настоящего Соглашения или действующего
                        правоприменительного законодательства.
                    </p>

                    <p>2.15. Пользователю запрещено:</p>

                    <p>
                        2.15.1. Публиковать одинаковые объявления с одного и того же адреса
                        электронной почты;
                    </p>

                    <p>
                        2.15.2. Публиковать схожие по содержанию объявления, где очевидно, что речь
                        идет об одном и том же предложении;
                    </p>

                    <p>
                        2.15.3. Дублировать одинаковые объявления с разных адресов электронной
                        почты;
                    </p>

                    <p>
                        2.15.4. Публиковать объявления в рубрике, которая не соответствует
                        содержанию объявления;
                    </p>

                    <p>
                        2.15.5. Публиковать объявления, в заголовке которых содержатся повторяющиеся
                        знаки пунктуации и/или небуквенные символы;
                    </p>

                    <p>
                        2.15.6. Публиковать объявления, описание и/или заголовок/фотографии которых
                        являются несвязанными, нечитаемыми;
                    </p>

                    <p>
                        2.15.7. Публиковать объявления с предложением нескольких товаров и услуг
                        одновременно;
                    </p>

                    <p>
                        2.15.8. Вставлять в объявлении ссылки на ресурсы, которые содержат
                        вредоносные элементы либо ссылки на главную страницу сайта;
                    </p>

                    <p>
                        2.15.9. Размещать объявление о товаре или услуге, если такое размещение
                        может привести к нарушению правоприменительного законодательства;
                    </p>

                    <p>
                        2.15.10. Объявления должны соответствовать географической области и городу,
                        выбранной в соответствующих функциональных настройках Сайта.
                    </p>

                    <p>
                        2.15.11. Разрешается разместить одно объявление касательно одного
                        конкретного предмета, объекта имущества, вакансии, услуги.
                    </p>

                    <p>
                        2.15.12. Объявления могут проходить выборочную пост или премодерацию
                        представителями Компании.
                    </p>

                    <p>2.15.13. Запрещается размещать объявления, рекламирующие продажу:</p>

                    <p>
                        спиртных напитков; сигарет и табачной продукции; наркотиков;
                        порнографических материалов или предметов; фармакологических продуктов,
                        медикаментов, наркотических веществ и прекурсоров; украденных, полученных не
                        законным способом товаров; предметов, представляющих опасность для жизни и
                        здоровья; несуществующих товаров; человеческих органов и органов животных;
                        специальных технических средств негласного получения информации;
                        государственных наград; персональных документов, а также бланков этих
                        документов; баз данных; огнестрельного, холодного, травматического оружия, а
                        также боеприпасов и комплектующих к ним; специальных средств активной
                        обороны, применяемых правоохранительными органами; клыков моржа, бивней
                        слона и мамонта не в изделии, а также драгоценных металлов и драгоценных
                        камней не в изделии; редких и запрещенных к продаже животных, любых других
                        запрещённых соответствующим законодательством товаров и услуг. С полным
                        списком запрещенных на сайте товаров, услуг и предложений можно ознакомиться
                        по ссылке: Компания вправе удалять объявления по требованию правообладателя
                        или компетентных государственных органов. Компания также оставляет за собой
                        право удалять любые объявления, которые по ее мнению не соответствуют
                        принципам и устоям общественной морали. Решение об удалении является
                        окончательным и обжалованию не подлежит.
                    </p>

                    <p>2.16. Администрация и модераторы Сайта (представители Компании) вправе:</p>

                    <p>
                        2.16.1. Вносить в текст объявления Пользователя правки, касающиеся
                        орфографии и пунктуации, не влияющие на общий смысл содержания объявления;
                    </p>

                    <p>
                        2.16.2. Переносить объявления в другие рубрики Сайта в случае выявления
                        более подходящей рубрики для их размещения;
                    </p>

                    <p>
                        2.16.3. Отказать в публикации объявлений, если они не соответствуют тематике
                        выбранных разделов, либо нарушают настоящее Соглашение, а также ограничивать
                        количество объявлений от одного Пользователя в целях удобства пользования
                        Сайтом без объяснения причин.
                    </p>

                    <p>
                        2.16.4. Не допускается указание некорректных характеристик предмета
                        предложения в объявлении. В том числе указание цены, не соответствующей
                        фактической цене реализации товара или услуги. Цена должна быть указана
                        полностью за весь товар или услугу.
                    </p>

                    <p>
                        2.16.5. Заголовок размещаемого объявления должен соответствовать тексту
                        самого объявления и не должен содержать контактную или персональную
                        информацию о Пользователе (телефон, электронные адрес, адрес
                        интернет-ресурса).
                    </p>

                    <p>
                        2.16.6. Фото, демонстрирующее товар/услугу, предлагаемые Пользователем к
                        продаже, должно соответствовать заголовку и тексту объявления. На фото
                        должен быть изображен только предлагаемый объект. Стоковые фото и/или фото,
                        загруженные из сети Интернет, запрещены.
                    </p>

                    <p>
                        2.16.7. Чтобы облегчить взаимодействие между Пользователями, Компания может
                        устанавливать ограниченный доступ к контактной информации других
                        пользователей. Право использования сведений, предоставленных другими
                        Пользователями, ограничивается настоящим Соглашением.
                    </p>

                    <p>
                        2.16.8. Компания не несет ответственности за содержание объявлений или
                        гиперссылок на ресурсы, указанные в описании объявлений пользователей.
                    </p>

                    <p>
                        2.16.9. Предметом объявлений могут быть товары или услуги, реализация
                        которых не запрещена и не ограничена в соответствии с законодательством
                        страны, в которых они реализуются, а также не противоречит данному
                        соглашению.
                    </p>

                    <p>
                        2.17. Для учета количества размещенных объявлений за единицу принимается 1
                        (одно) Размещение.
                    </p>

                    <TextPageHeader>
                        3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ И СОГЛАСИЕ НА НЕЕ
                    </TextPageHeader>

                    <p>
                        3.1. При размещении объявлений пользователи Сайта предоставляют Компании
                        право на обработку их персональных данных на условиях, предусмотренных в
                        Политике Конфеденциальности.
                    </p>

                    <TextPageHeader>4. СВЕДЕНИЯ, ПРЕДОСТАВЛЯЕМЫЕ ПОЛЬЗОВАТЕЛЕМ </TextPageHeader>
                    <p>
                        4.1. Компания сохраняет за собой право связываться с Пользователем:
                        направлять информационные сообщения на электронный и физический адрес,
                        указанный при регистрации, а также отправлять сообщения на мобильный телефон
                        Пользователя.
                    </p>

                    <p>
                        4.2. Сбор информации осуществляется путем самостоятельного с использованием
                        программных средств Сайта указания пользователем соответствующих данных,
                        требуемых для размещения объявлений на нём.
                    </p>

                    <p>
                        4.3. Информация технического характера, содержащаяся в системе, например,
                        ip-адреса, в соответствии с общими правилами интернет-сообщений,
                        используется Компанией в целях, связанных с обслуживанием сетевого
                        оборудования, а также для агрегации общей статистической, демографической
                        информации (например, о регионе, из которого было осуществлено соединение
                        пользователем).
                    </p>

                    <p>
                        4.4. Компания сохраняет данные последнего доступа в систему пользователя, с
                        целью обеспечения высокого качества предоставляемых сервисов,
                        приспосабливаемых к индивидуальным потребностям и интересам пользователя.
                    </p>

                    <p>
                        4.5. Пользователь осуществляет доступ к сервисам Сайта в течении временных
                        промежутков непрерывного использования – сессий. Зарегистрированный
                        Пользователь осуществляет доступ к части Сайта, доступной исключительно
                        после введения им логина и пароля, по крайней мере, один раз в течение
                        сессии.
                    </p>

                    <p>
                        4.6. Отключение сохранения данных последнего доступа в систему в настройках
                        браузера не влияет на возможность использования сервисов Сайта в целом,
                        однако могут ограничить их функционал для Пользователя.
                    </p>

                    <p>
                        4.7. Данные последнего доступа в систему также используются для сбора
                        статистической информации об использовании услуг Пользователями.
                    </p>

                    <p>
                        4.8. Пользователю запрещается предоставлять информацию в нарушение
                        настоящего Соглашения или прав третьих лиц, в частности, информация не
                        должна содержать:
                    </p>

                    <p>
                        вульгарных, оскорбительных выражений; пропаганду ненависти, насилия,
                        дискриминации, расизма, ксенофобии, межнациональных конфликтов; призывы к
                        насилию и противоправным действиям; данные, нарушающие личные права или
                        права интеллектуальной собственности третьих лиц; информацию, способствующую
                        мошенничеству, обману или злоупотреблению доверием; информацию, ведущую к
                        совершению сделок с крадеными или поддельными предметами; информацию,
                        нарушающую или посягающую на собственность третьих лиц, коммерческую тайну
                        либо право на неприкосновенность частной жизни; личную или идентифицирующую
                        информацию о других лицах без явно выраженного согласия этих лиц;
                        информацию, содержащую сведения, посягающие на неприкосновенность частной
                        жизни, оскорбляющую чью-либо честь, достоинство или деловую репутацию;
                        информацию, содержащую клевету или угрозы в адрес кого бы то ни было;
                        информацию, носящую характер порнографии; информацию, наносящую вред
                        несовершеннолетним; ложную и вводящую в заблуждение информацию; вирусы или
                        какие-либо другие технологии, могущие нанести вред сайтам, компании или
                        другим пользователям; информацию о сервисах, считающихся аморальными, таких
                        как: проституция, ростовщичество или другие формы, противоречащие нормам
                        морали или закона; ссылки или информацию о сайтах, конкурирующих с сервисами
                        компании; информацию, представляющую собой «спам», «письма счастья», «схемы
                        пирамид» или нежелательную или лживую коммерческую рекламу; информацию,
                        распространяемую информационными агентствами; информацию с предложением
                        заработка в интернет, без указания физического адреса и прямых контактов
                        работодателя; информацию с предложением франшизы, многоуровневого и сетевого
                        маркетинга, агентской деятельности, торгового представительства или любой
                        другой деятельности, которая требует вербовки других членов, субагентов,
                        субдистрибьютеров и пр.; сведения исключительно рекламного характера без
                        предложения конкретного товара или услуги; информацию, иным образом
                        нарушающую правоприменительное законодательство юрисдикции, для которой
                        предназначено объявление.
                    </p>

                    <p>
                        4.9. Запрещается размещать объявления, рекламирующие продажу: спиртных
                        напитков; сигарет и табачной продукции; наркотиков; порнографических
                        материалов или предметов; фармакологических продуктов и медикаментов;
                        украденных, полученных криминальным способом товаров; предметов,
                        представляющих опасность для жизни и здоровья; несуществующих товаров;
                        человеческих органов; государственных наград; персональных документов, а
                        также бланков этих документов; баз данных; огнестрельного и холодного
                        оружия; клыков моржа; бивней слона и мамонта не в изделии, а также
                        драгоценных металлов и драгоценных камней не в изделии; редких и запрещенных
                        к продаже животных любых других запрещённых соответствующим
                        законодательством товаров и услуг. Полный список запрещенных на сайте
                        товаров и услуг доступен по ссылке.
                    </p>

                    <p>
                        4.10. При подаче объявления с предложениями услуг/работ, подлежащих
                        лицензированию, в тексте объявления требуется указывать номер лицензии и
                        наименование органа, выдавшего лицензию. Компания не несет ответственности
                        за наличие, соответствие и достоверность предоставляемых/публикуемых
                        Пользователем документов. Покупатель, в случае необходимости, самостоятельно
                        запрашивает необходимые документы непосредственно у Пользователя,
                        разместившего объявление.
                    </p>

                    <p>4.11. Система рейтингов и отзывов</p>

                    <p>
                        В рамках Сервиса (согласно определению Пользовательского соглашения - Сайт /
                        Сайты) предусмотрена система рейтингов и отзывов, которая позволяет
                        Пользователю оставить субъективную оценку бизнес - продавцов относительно
                        своего транзацийного опыта с таким продавцом. Трансакционный опыт
                        понимается, в частности, как: предоставление Пользователям четкого и
                        надежного описания Товара, формы и способа общения между Пользователями,
                        отзывчивость, вовлеченность, время отправки и доставки приобретенного
                        Товара, пунктуальность. Финальным основанием для оценки может быть любое
                        взаимодействие между Пользователями, и не обязательно заключения транзакции
                        (сделки). Рейтинг выдается в порядке, форме, сроки и категориях, указанных
                        на Сайте. Рейтинг будет представлен на Сайте в объявлениях Пользователя по
                        шкале от 1 до 5 (до одного знака после запятой) на основе среднего уровня
                        всех рейтингов, предоставленных Пользователями. Выбранный отзыв доступен
                        только для Пользователя, в отношении которого он был оставлен, в аккаунте
                        этого пользователя. Дополнительно, такой отзыв будет играть вспомогательную
                        роль для проверки Компанией действий Пользователей. Если Пользователю
                        присвоено более одного рейтинга одним Пользователем, к среднему рейтингу,
                        Пользователя, будет включен только последний оставленный рейтинг. Отзыв не
                        может нарушать положения действующего законодательства, и не может
                        содержать: ненормативную лексику, нецензурный и порнографический контент,
                        или контент, разжигающий ненависть, расизм, ксенофобию и межнациональные
                        конфликты, адреса веб-сайтов или ссылки на другие веб-сайты, рекламный
                        контент, другой коммерческий контент, данные Пользователей или иных
                        физических лиц, в том числе, но не исключительно: имя и фамилия, место
                        жительства, номер телефона, адрес, номер банковского счета, контент,
                        нарушающий права третьих лиц, включая права интеллектуальной собственности,
                        личные права, включая доброе имя, репутацию других Пользователей или третьих
                        лиц, контент, который является результатом договоренности с другими
                        Пользователями или третьими сторонами с целью влияния на рейтинг или отзывы,
                        контент, который является ложным, клеветническим содержит проявления
                        недобросовестной конкуренции. 6. JBF вправе не включать предоставлен рейтинг
                        в средний рейтинга Пользователя, или удалить рейтинг или отзыв полностью или
                        частично, когда рейтинг или отзыв: касается пользователя, который не
                        является оцениваемым Пользователем, предоставляется Пользователем в
                        отношении самого себя, предоставляется работниками, родственниками, родней и
                        т.д. Пользователя, предоставляется в результате контакта с Пользователем
                        только с целью присвоения рейтинга и/или оценки (например, для
                        искусственного завышения или занижения уровня доверия к Пользователю), был
                        предоставлен с аккаунта с адреса электронной почты, созданного исключительно
                        для регистрации учетной записи, был предоставлен таким образом, что
                        указывает на автоматизацию размещения, или размещение с помощью решений,
                        которые избегают анализа сетевого трафика, иным образом нарушает условия
                        Пользовательского соглашения JBF.kz. Что касается содержания отзыва,
                        Пользователь предоставляет JBF.kz (Компании) неисключительную,
                        территориально неограниченную и бесплатную лицензию на условиях, указанных в
                        пункте 6.3. Пользовательского соглашения, и других применимых положений
                        соглашения.
                    </p>

                    <TextPageHeader>5. ПРИЧИНЫ УДАЛЕНИЯ ОБЪЯВЛЕНИЙ</TextPageHeader>

                    <p>
                        5.1. Объявление Пользователя может быть удалено Компанией по причине
                        нарушения Пользователем условий настоящего Соглашения, а также по следующим
                        причинам:
                    </p>

                    <p>
                        У данного Пользователя уже есть на Сайте активное аналогичное объявление с
                        рекламой этого товара/услуги; Содержащаяся в объявлении информация
                        противоречит настоящему Соглашению, правилам публикации объявлений
                        https://jbf.kz/placement и/или законодательству; Содержащаяся в объявлении
                        информация ложна; Заголовок объявления не содержит информацию о
                        предлагаемом/востребованном товаре/услуге; Веб-сайт и мобильные данные:
                        Компания может автоматически получать и регистрировать на своих серверах
                        информацию из браузера пользователя или любого устройства, включая IP-адрес,
                        геолокацию Пользователя, программного обеспечения и аппаратные атрибуты,
                        страницы, которые запрашивает Пользователь, данные, которые содержаться в
                        базах данных браузера, включая SQL базы данных, мобильные идентификаторы
                        (включая идентификаторы мобильных устройств, такие как Google Advertisement
                        ID, IFA or IFV), информацию об использовании приложения, и/или информацию о
                        других используемых устройствах или информацию системного уровня. Это может
                        происходить на Сайте или на мобильном приложении, или на сервисах третьих
                        лиц. Дополнительная информация о том, как пользователи могут контролировать
                        и/или блокировать такой сбор информации находится ниже находится в разделе 6
                        данной политики. Заголовок или комментарий к фотографии содержат ссылку на
                        Интернет-ресурсы; Фотография не имеет очевидной смысловой связи с текстом
                        объявления или не служит цели адекватно проиллюстрировать текст объявления;
                        Фотография содержит элементы пользовательского интерфейса, абстрактные
                        рисунки и т. п.; Фотография содержит какую-либо рекламную информацию (ссылка
                        на сайт, e-mail, номер телефона, Skype, IСQ, ID в соцсетях, ID других
                        мессенджеров и подобное); Фотография плохого качества, изображаемый предмет
                        неразличим; Объявление подано в рубрику, которая не соответствует смыслу
                        поданного объявления. Компании предоставлена жалоба от обладателя
                        имущественных прав интеллектуальной собственности, и/или запрос
                        уполномоченного органа государственной власти; Компании предоставлена
                        обоснованная жалоба другого Пользователя о нарушении его прав в объявлении.
                    </p>

                    <TextPageHeader>6. ПРАВА И ОБЯЗАННОСТИ СТОРОН</TextPageHeader>
                    <p>
                        6.1. Все объекты, доступные при помощи сервисов Компании, в том числе
                        элементы дизайна, текст, графические изображения, иллюстрации, видео,
                        программы для ЭВМ, базы данных, музыка, звуки и другие объекты, а также
                        любой контент, размещенный на сервисах Сайта, являются объектами
                        исключительных прав Компании, Пользователей и других правообладателей.
                    </p>

                    <p>
                        6.2. Использование контента, а также каких-либо иных элементов сервисов
                        возможно только в рамках функционала, предлагаемого тем или иным сервисом
                        Сайта. Никакие элементы содержания сервисов Сайта, а также любой контент,
                        размещенный на сервисах Сайта, не могут быть использованы иным образом без
                        предварительного разрешения правообладателя. Под использованием
                        подразумеваются, в том числе, но неисключительно: воспроизведение,
                        копирование, переработка, распространение на любой основе и т.д.
                    </p>

                    <p>
                        6.3. Чтобы предоставить Компании право публиковать сведения, предоставленные
                        Пользователем, Пользователь предоставляет Компании действующее повсеместно,
                        бессрочное, безотзывное, неисключительное, сублицензируемое право
                        использования, публикации, сбора, демонстрации, копирования, дублирования,
                        воспроизведения относительно авторских прав, публикаций и баз данных,
                        которое имеет Пользователь, а также относительно предоставленных им
                        сведений, изображений и фотографий на всех известных или не известных
                        информационных носителях. Перечисленные выше права предоставляется Компании
                        безвозмездно и бессрочно. При этом Пользователь сохраняет все права
                        собственности на содержание размещаемой в объявлении информации. Помимо
                        вышеперечисленного, Пользователь предоставляет право на доступ к размещенной
                        им информации всем пользователям Сайта.
                    </p>

                    <p>
                        Пользователь соглашается с тем, что текст, фотографии, и другие материалы,
                        добавленные к объявлению, могут быть использованы Компанией при подготовке
                        рекламных материалов, статей, отчетов, анализов и т.д., и использоваться
                        Компанией по собственному усмотрению без дополнительного согласия со стороны
                        Пользователя, без выплаты вознаграждения.
                    </p>

                    <p>
                        6.4. Воспользовавшись сервисами Сайта, Пользователь подтверждает, что несет
                        исключительную ответственность за содержание размещенных им объявлений, а
                        также обладает всеми необходимыми правами, лицензиями, разрешениями на
                        размещение информации в объявлении на Сайте, включая без ограничений все
                        патенты, товарные знаки, коммерческие тайны, авторские права, или же имеет
                        соответствующее письменное согласие, лицензию или разрешение всех
                        идентифицируемых в объявлении лиц и компаний для использования их имен или
                        изображений.
                    </p>

                    <p>
                        6.5. Размещая на Сайте объявление с изображениями без знака охраны
                        авторского права Пользователь соглашается с использованием изображения и/или
                        его части в интересах Компании без выплаты вознаграждения.
                    </p>

                    <p>6.6. Пользователь обязуется:</p>

                    <p>
                        Не предпринимать никаких действий, которые могут привести к
                        непропорционально большой нагрузке на инфраструктуру Сайта; Не использовать
                        автоматические программы для получения доступа на Сайт без письменного
                        разрешения Компании; Не копировать, не воспроизводить, не изменять, не
                        распространять и не представлять общественности любую информацию,
                        содержащуюся на Сайте (кроме сведений, предоставленных самим Пользователем)
                        без предварительного письменного разрешения Компании; Не препятствовать и не
                        пытаться препятствовать работе и другой деятельности на Сайте; а также не
                        препятствовать действию автоматических систем или процессов, а также других
                        мероприятий, с целью воспрепятствовать или ограничить доступ на Сайт; Не
                        использовать сведения, предоставленные другими Пользователями, в иных целях,
                        кроме как для совершения сделки непосредственно с этим Пользователем, без
                        письменного разрешения другого Пользователя. В настоящий пункт Соглашения не
                        включены персональные данные Пользователя, которые последний предоставляет
                        Компании при регистрации. 6.7. Пользователю запрещается:
                    </p>

                    <p>
                        Обсуждение действий модераторов и администрации Сайта иным образом, кроме
                        как посредством электронной переписки с модераторами; Использование имен
                        Пользователей, похожих на имена других Пользователей, с целью выдать за них
                        себя и писать сообщения от их имени. Использовать в тексте объявления, в
                        фотографиях статусы, которые не предоставляются Компанией (например, «лучший
                        продавец», «рекомендован JBF», «проверено JBF» и т.д.). Использовать в
                        тексте объявления, в фотографиях знак для товаров и услуг «JBF» в любых его
                        проявлениях, обозначения, схожие с знаком для товаров и услуг, обозначение
                        «JBF», и любые другие производные.
                    </p>

                    <p>
                        6.8. Доступ к персональным данным Пользователя со стороны других
                        Пользователей возможен только при наличии письменного согласия Пользователя
                        на подобный доступ или во исполнение требований соответствующего
                        законодательства.
                    </p>

                    <p>
                        6.9. Компания обязуется прилагать все усилия для должного исполнения своих
                        обязанностей по настоящему Соглашению, включая нормальную работу сервисов
                        Сайта и нераспространение третьим лицам персональных данных, предоставленных
                        Пользователем, за исключением случаев, предусмотренных законодательством.
                    </p>

                    <p>
                        6.10. Компания может периодически устанавливать ограничения по использованию
                        сервисов Сайта, в частности, максимальное количество дней хранения
                        объявлений и их размер. Компания вправе в любой момент изменить или
                        прекратить работу сервисов Сайта или их части с уведомлением Пользователя
                        или без него, не неся при этом ответственности за подобные изменения или
                        прекращения.
                    </p>

                    <p>
                        6.11. Чтобы поддерживать высокое качество своих услуг, Компания оставляет за
                        собой право ограничить количество активных объявлений Пользователя на Сайте,
                        а также ограничить действия Пользователя на Сайте.
                    </p>

                    <p>
                        6.12. Компания может запретить Пользователю доступ на Сайт, если
                        Пользователь нарушает условия настоящего Соглашения. Факт нарушения
                        считается подтвержденным, если Пользователь был оповещен администрацией
                        Сайта о ведении деятельности, нарушающей правила Сайта и прав третьих лиц.
                        Компания оставляет за собой право в любой момент удалить или отключить
                        учетную запись Пользователя, а также удалить все размещенные объявления
                        Пользователя, оставив предварительное уведомление Пользователя о таком
                        отключении на свое усмотрение, и не неся при этом ответственность за свои
                        действия перед Пользователем и третьими лицами.
                    </p>

                    <p>
                        6.13. Компания вправе по собственному усмотрению отвергать, удалять или
                        перемещать любое размещенное на Сайте объявление за нарушение настоящего
                        Соглашения.
                    </p>

                    <p>
                        6.14. Компания вправе передать Сайт со всеми его сервисами и содержанием,
                        включая личную информацию Пользователей, своему правопреемнику по договорам
                        или иным основаниям. Передача и уведомление Пользователей о такой передаче
                        осуществляется в соответствии с требованиями действующего законодательства.
                    </p>

                    <p>
                        6.15. Пользователь вправе направлять жалобы по вопросам работы Сайта через
                        форму обратной связи, которые будут рассмотрены в течение двух рабочих дней
                        с момента их поступления или с момента получения полной информации по сути
                        жалобы. Компания имеет право по своему усмотрению прекратить рассмотрение
                        жалобы, и/или ограничить количество ответов Пользователям, нарушающим
                        условия Соглашения. Оператор, предоставляющий поддержку Пользователей по
                        телефону, и/или отвечающий на телефонный звонок Пользователя, имеет право
                        прекратить звонок в случае угроз Пользователя в сторону Оператора, Компании,
                        нецензурной лексики, перевод разговора на личные темы, беспредметности
                        разговора.
                    </p>

                    <p>
                        6.16. Компания оставляет за собой право в любой момент потребовать от
                        Пользователя подтверждения данных, указанных им при регистрации, и запросить
                        в связи с этим подтверждающие документы (в частности – копии/заверенные
                        копии документов, удостоверяющих личность), не предоставление которых, по
                        усмотрению Пользователя, может быть приравнено к предоставлению
                        недостоверной информации. В случае если данные Пользователя, указанные в
                        предоставленных им документах, не соответствуют данным, указанным при
                        регистрации, а также в случае когда данные, указанные при регистрации, не
                        позволяют идентифицировать Пользователя, Компания вправе отказать
                        Пользователю в доступе к использованию сервисов Сайта с предварительным
                        уведомлением Пользователя или без такового.
                    </p>

                    <p>
                        6.17. Компания имеет право в любой момент прекратить поддержку
                        (функционирование) определенных версий приложения jbf.kz для мобильных
                        устройств на базе любых операционных систем. Для продолжения использования
                        приложения, Пользователю необходимо обновить приложение до новой версии, или
                        же загрузить приложение в актуальной версии.
                    </p>

                    <p>
                        6.18. Компания на основании анализа деятельности Пользователя на Сайте
                        вправе относить Пользователя в категории «Бизнес-пользователь», независимо
                        от того, отнес ли себя Пользователь к данной категории при публикации
                        объявления. Для отнесения Пользователя к категории «Бизнес» достаточно
                        наличия в действиях Пользователя одного из следующих признаков:
                    </p>

                    <p>
                        Использование при размещении объявления профессиональных фотографий
                        (стоковых фотографий); Наличие в тексте объявления ссылки на веб-сайт /
                        страницу веб-сайта; Наличие в тексте объявления информации о возможности
                        выбора другой модели, наличия товара в ассортименте, разных размеров товара,
                        возможности заказа товара, предоставления Пользователем гарантии на товар;
                        Приобретение Пользователем любого пакета размещений. Компания относит
                        Пользователя к категории Бизнес-пользователь для тех рубрик/подрубрик, в
                        которых Пользователь осуществил действия, предусмотренные данным пунктом
                        Соглашения, для всех остальных рубрик/подрубрик Пользователь не приобретает
                        статуса Бизнес-пользователь.
                    </p>

                    <p>
                        6.19. Компания имеет право заблокировать учетную запись Пользователя без
                        возможности ее восстановления, если с момента ее блокировки или
                        приостановления прошло более 6 (шести) месяцев. Для целей данного пункта
                        причиной блокировки (приостановления) может быть как нарушение Пользователем
                        условий Пользовательского соглашения, так и подозрительная активность в
                        учетной записи.
                    </p>

                    <p>
                        Мы вынуждены прибегать к таким мерам, так как по истечении 6 месяцев не
                        имеем возможность проверить данные учетной записи, и обеспечить безопасность
                        ее использования.
                    </p>

                    <p>
                        6.20. В случае SMS-верификация. Компания имеет право в любой момент
                        верифицировать Пользователя посредством SMS-верификации. В случае если
                        Пользователь не прошел верификацию, Компания имеет право ограничить
                        возможности пользования Сайтом, а именно размещение, редактирование,
                        продление, обновление объявлений. Выборка Пользователей, которые подлежат
                        верификации, осуществляется Компанией по своему собственному усмотрению. Все
                        Пользователи, которые были верифицированы с помощью SMS-верификации могут
                        использовать номер мобильного телефона для входа в учетную запись/аккаунт,
                        используя пароль, указанный при регистрации.
                    </p>

                    <p>
                        Для прохождения SMS-верификации может использоваться только номер
                        казахстанских операторов сотовой связи +7 (7**). Компания имеет право по
                        своему усмотрению не допускать верификацию Пользователей, использующих
                        номера телефонов определенных операторов сотовой связи (в том числе
                        виртуальных), CDMA-операторов.
                    </p>

                    <TextPageHeader>7. ОПЛАТА УСЛУГ </TextPageHeader>
                    <p>
                        7.1. Заказ (акцептирование) платных услуг JBF осуществляется Пользователем
                        на сайте по адресу https://jbf.kz/cabinet/tariff, в личной учетной записи, а
                        также в любых других соответствующих разделах Сайта.
                    </p>

                    <p>
                        7.2. Пользователь обязуется ознакомиться с ценами на платные услуги
                        Компании, размещенных на Сайте, после чего может заказать такие услуги.
                        Подробная информация о стоимости всех услуг указана.
                    </p>

                    <p>
                        7.3. Оплата платных услуг Компании осуществляется в порядке, указанном по
                        адресу
                    </p>

                    <p>
                        7.4. Возврат оплаченных денежных средств за не оказанные услуги
                        осуществляется Компанией в следующих случаях:
                    </p>

                    <p>
                        Автоматически функциональной системой Сайтов за каждое удаленное объявление
                        при модерации (кроме объявлений, удаленных как неактуальное), если для
                        объявления приобретены платные услуги (Наборы услуг для продвижения
                        объявлений, Топ-объявление, VIP-объявление, Поднять вверх списка (за
                        исключение одноразового поднятия), Лого в списке объявлений на 30 дней).
                        Возврат денежных средств осуществляется за те часы, в которых услуга не
                        предоставлялась. Для осуществления расчета суммы средств, подлежащих
                        возврату, принимаются астрономические часы, возврат происходит за те часы,
                        когда услуга фактически не предоставлялась. Если по причине любой
                        технической неполадки услуга не предоставлялась, возврат оплаченных денежных
                        средств осуществляется уполномоченным Компанией администратором Сайта/ов
                        после предоставления пользователем доказательств оплаты. В таком случае
                        возврат денежных средств осуществляется на банковскую карту, с которой была
                        произведена оплата соответствующей услуги. Если вследствие истечении срока
                        действия карты, или по другим причинам возврат средств не представляется
                        возможным, денежные средства возвращаются на учетную запись Пользователя с
                        возможностью использования денежных средств в будущем. При оплате услуг с
                        удалённых/заблокированных Компанией учетных записей, которые были
                        продублированы Пользователем в нарушение условий данного Соглашения. Такой
                        возврат осуществляется Компанией исключительно по обращению Пользователя, а
                        возврат денежных средств осуществляется на банковскую карту, с которой была
                        произведена оплата соответствующей услуги. Если вследствие истечении срока
                        действия карты, или по другим причинам возврат средств не представляется
                        возможным, денежные средства не возвращаются. При блокировании учетной
                        записи за размещение объявлений о запрещённых товарах, услугах,
                        предложениях, перечень которых указан по адресу: https://jbf.kz/placement,
                        денежные средства не возвращаются.
                    </p>

                    <p>
                        Возврат уплаченных Пользователем денежных средств за уже оказанные Компанией
                        в полном объеме и надлежащего качества услуги не осуществляется.
                    </p>

                    <p>
                        7.5. При систематических (два и более раза) нарушениях условий этого
                        Соглашения, денежные средства, оплаченные Пользователем, не возвращаются. В
                        случае блокировки учётной записи в связи с выявлениям вероятности, что в
                        результате умышленных нарушений пользователем &quot;Пользовательского
                        соглашения&quot; другие пользователи могут/могли получить убытки, денежные
                        средства, уплаченные за услуги, которые не были получены до такой
                        блокировки, не возвращаются.
                    </p>

                    <p>
                        7.6. Пользователь соглашается, что подтверждением оказания услуг со стороны
                        ИП Жанан является Выписка из личного счета, которая формируется Исполнителем
                        на основании данных, доступных на аккаунте Пользователя. Исполнитель
                        предоставляет Выписку из личного счета только по запросам Пользователей.
                    </p>

                    <p>
                        7.7. Компания предоставляет Акты приема-передачи оказанных услуг Бизнес
                        Пользователям, которые являются субъектами предпринимательской деятельности,
                        зарегистрированными в порядке, установленном законодательством Республики
                        Казахстан.
                    </p>

                    <p>
                        7.8. Такой Пользователь обязуется в течение 10 (десяти) календарных дней
                        после получения Акта в электронной форме подписать Акт и направить Компании
                        подписанный экземпляр Акта, или в тот же срок направить Компании
                        мотивированный отказ от подписания Акта с указанием причин отказа от
                        подписания. В случае если в указанный срок Пользователем не будет отправлен
                        подписанный Акт, или мотивированный отказ от его подписания, платные услуги,
                        предоставляемые Компанией, считаются принятыми без замечаний по качеству и
                        сроку предоставления.
                    </p>

                    <p>
                        7.9. Акты приема-передачи оказанных услуг предоставляются Бизнес
                        Пользователям, которые являются субъектами предпринимательской деятельности
                        только в случае оплаты услуг непосредственно Исполнителю, без использования
                        услуг платежных провайдеров.
                    </p>

                    <TextPageHeader>8. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ КОМПАНИИ </TextPageHeader>
                    <p>
                        8.1. Воспользовавшись сервисами Сайта, Пользователь подтверждает свое
                        согласие с тем, что он использует Сайт и его сервисы на свой страх и риск
                        «как есть», оценивает и несет все риски, связанные с использованием
                        размещенных на Сайте объявлений, а Компания, в том числе ее руководство,
                        сотрудники и агенты, не несут никакой ответственности за содержание
                        размещенных на Сайте объявлений, за любые убытки и потери, ставшие
                        результатом использования размещенных на Сайте объявлений.
                    </p>

                    <p>
                        8.2. Компания не является организатором/инициатором сделки между
                        Пользователями или ее стороной. Сайт является торговой коммуникационной
                        платформой, которая предоставляет возможность Пользователям размещать для
                        реализации, продавать и приобретать разрешенные законом товары и услуги в
                        любое время, откуда угодно и по любой цене.
                    </p>

                    <p>
                        8.3. Компания не может контролировать достоверность размещаемой
                        пользователями в объявлениях информации. Компания не несет ответственности
                        за какой-либо ущерб, причиненный в результате совершения сделки или
                        ненадлежащего поведения какой-либо из сторон сделки.
                    </p>

                    <p>
                        8.4. Компания не несет ответственности за поведение Пользователей или за
                        предлагаемые ими товары/услуги, указанные в размещаемых объявлениях. Все
                        споры и конфликты между Пользователями решаются ими самостоятельно без
                        привлечения Компании.
                    </p>

                    <p>
                        8.5. Компания не несет ответственности за любой несанкционированный доступ
                        или использование серверов Компании и/или любой хранящейся на них информации
                        о пользователях, а также за любые ошибки, вирусы, «троянские кони» и т.д.,
                        которые могут быть переданы на Сайт или через Сайт третьими лицами.
                    </p>

                    <p>
                        8.6. Качество, безопасность, законность и соответствие товара или услуги их
                        описанию, а также возможность Пользователя продать или приобрести
                        товар/услугу находятся вне контроля Компании.
                    </p>

                    <p>
                        8.7. Компания призывает Пользователей соблюдать осторожность и сохранять
                        здравый смысл при пользовании услугами Сайта. Пользователь должен принять во
                        внимание, что его контрагент может быть несовершеннолетним или выдавать себя
                        за другое лицо. Использование услуг Компании подразумевает, что Пользователь
                        осознает и принимает эти риски, а также соглашается, что Компания не несет
                        ответственности за действия или бездействие со стороны Пользователя.
                    </p>

                    <p>
                        8.8. Пользователь не может делать вывод, что предложение, продажа и
                        приобретение какого-либо товара или услуги является действительным и
                        легальным исходя из факта размещения, продажи и приобретения товара или
                        услуги на Сайте. Компания не несет ответственность за завершение сделки
                        Пользователем Сайта. Пользователь принимает на себя полную ответственность
                        за свои действия.
                    </p>

                    <p>
                        8.9. Если у Пользователя возникают претензии к другому Пользователю в
                        результате использования последним услуг Сайта, Пользователь соглашается
                        предъявлять эти требования самостоятельно и без вмешательства со стороны
                        Компании, а также освобождает Компанию (наряду с ее дочерними компаниями,
                        аффилированными лицами, служащими, директорами, уполномоченными и
                        сотрудниками) от всех требований, обязательств, компенсаций по возмещению
                        ущерба, убытков, затрат и издержек, включая адвокатские гонорары, известных
                        или неизвестных, возникших в результате или в связи с такими требованиями.
                    </p>

                    <p>
                        8.10. Бездействие со стороны Компании в случае нарушения Пользователем либо
                        иными пользователями положений Соглашения не лишает Компанию права
                        предпринять соответствующие действия в защиту своих интересов позднее, а
                        также не означает отказа Компании от своих прав в случае совершения в
                        последующем подобных либо сходных нарушений.
                    </p>

                    <p>
                        8.11. Пользователь вправе сообщить Компании о факте нарушения его прав
                        другим Пользователем, воспользовавшись ссылкой. В случае обоснованности
                        жалоб Пользователя Компания удаляет объявление, которое нарушает права
                        Пользователя.
                    </p>

                    <p>
                        8.12. Компания не несет ответственности за неисполнение или затруднения в
                        исполнении обязательств по предоставлению доступа к Сайту из-за
                        непредвиденных обстоятельств непреодолимой силы, последствий которых
                        невозможно избежать или преодолеть (таких как решения властей, трудовые
                        споры, несчастные случаи, обрывы в общей системе коммуникаций и т.д.).
                    </p>

                    <p>
                        8.13. Компания не несет ответственности за неполадки в работе Сайта,
                        вызванные техническими перебоями в работе оборудования и программного
                        обеспечения.
                    </p>

                    <p>
                        8.14. Ни при каких условиях Компания, ее руководство, сотрудники и агенты,
                        не несут ответственности за прямой, косвенный, случайный, специальный ущерб
                        и штрафные убытки (даже если Компания была предупреждена о возможности
                        такого ущерба) в результате использования Сайта и его сервисов
                        Пользователем, включая без ограничения случаи, в которых ущерб стал
                        результатом использования или неправильного использования Сайта и его
                        сервисов.
                    </p>

                    <p>
                        8.15. Ничто в Соглашении не может пониматься как установление между
                        Пользователем и Компанией агентских отношений, отношений товарищества,
                        отношений по совместной деятельности, отношений личного найма, либо каких-то
                        иных отношений, прямо не предусмотренных Соглашением.
                    </p>

                    <p>
                        8.16. Компания несет ответственность за рекламу, размещенную на сервисах
                        Сайта, в пределах, установленных правоприменительным законодательством.
                    </p>

                    <p>
                        8.17. Компания не несет ответственности за сохранность информации с
                        аккаунта, возможность использования сервисов Сайта, сохранность и
                        возможность использования средств на личном счете Пользователя, в случае
                        блокировки / запрета использования сторонних сервисов, с помощью которых
                        Пользователь осуществляет регистрацию и / или вход на Сайт, находящихся вне
                        контроля Компании.
                    </p>

                    <TextPageHeader>
                        9. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ И ПРЕКРАЩЕНИЕ ОКАЗАНИЯ УСЛУГ САЙТА
                    </TextPageHeader>
                    <p>
                        9.1. Настоящее Соглашение вступает в силу с момента начала использования
                        Пользователем какого-либо сервиса Сайта либо с момента регистрации
                        Пользователя на Сайте и действует бессрочно.
                    </p>

                    <p>
                        9.2. Пользователь имеет право прекратить свою регистрацию на Сайте в
                        одностороннем порядке, без предварительного уведомления об этом Компании и
                        объяснения причин.
                    </p>

                    <p>
                        9.3. В случае если Компанией были внесены какие-либо изменения в Соглашение
                        в порядке, предусмотренном пунктом 9.1. Соглашения, с которыми Пользователь
                        не согласен, он обязан прекратить использование сервисов Сайта. Факт не
                        прекращения использования сайта является подтверждением согласия
                        Пользователя с соответствующей редакцией Соглашения.
                    </p>

                    <p>
                        9.4. Расторжение Соглашения со стороны Компании может произойти в случаях:
                    </p>

                    <p>
                        9.4.1. Нарушения положений данного Соглашения, нанесения любого вреда
                        Компании, в том числе её репутации, или пользователям JBF;
                    </p>

                    <p>
                        9.4.2. Совершения других действий, которые противоречат политике Компании.
                    </p>

                    <p>
                        9.5. Правоотношения могут быть возобновлены только после принятия
                        администрацией Компании соответствующего решения.
                    </p>

                    <TextPageHeader>10. ВНЕСЕНИЕ ИЗМЕНЕНИЙ В СОГЛАШЕНИЕ</TextPageHeader>
                    <p>
                        10.1. Соглашение может быть изменено Компанией без какого-либо специального
                        уведомления, новая редакция Соглашения вступает в силу с момента ее
                        размещения в сети Интернет по указанному в настоящем абзаце Соглашения
                        адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая
                        редакция Соглашения находится на странице по адресу.
                    </p>

                    <TextPageHeader>11. ПРОЧИЕ УСЛОВИЯ</TextPageHeader>
                    <p>
                        11.1. Системные сообщения Сайта, относящиеся к объявлениям Пользователя,
                        доставляются по электронному адресу, предоставленному Пользователем при
                        размещении объявления на Сайте. В случае нежелания Пользователя получать
                        такие сообщения Пользователь вправе удалить соответствующие объявления с
                        Сайта.
                    </p>

                    <p>
                        11.2. Информационные сообщения, предназначенные для широкого круга
                        Пользователей, публикуются на Сайте и/или рассылаются по электронным адресам
                        Пользователей, подтвердивших свое согласие на получение таких сообщений в
                        процессе публикации объявлений/регистрации на Сайте.
                    </p>

                    <p>
                        11.3. Пользователи вправе отказаться от получения информационных сообщений
                        на свой электронный адрес в любой момент через функцию «Отписаться от
                        рассылки», содержащуюся в учетной записи Пользователя.
                    </p>

                    <p>
                        11.4. При возникновении между Сторонами споров и разногласий по настоящему
                        Соглашению или в связи с ним Стороны обязуются разрешать их путем
                        переговоров. Если какие-либо споры, разногласия или требования, возникающие
                        из настоящего Соглашения, или в связи с ним, в том числе, касающиеся его
                        исполнения, нарушения, прекращения или недействительности, не могут быть
                        разрешены путем переговоров, то эти споры рассматривается в соответствии с
                        действующим законодательством в судебном порядке.
                    </p>

                    <p>
                        11.5. Настоящее Соглашение регулируется и толкуется в соответствии с
                        законодательством Республики Казахстан. Вопросы, не урегулированные
                        настоящим Соглашением, подлежат разрешению в соответствии с действующим
                        законодательством. Все возможные споры, вытекающие из отношений,
                        регулируемых настоящим Соглашением, разрешаются в порядке, установленном
                        законодательством, по нормам права Республики Казахстан.
                    </p>

                    <p>
                        11.6. Везде по тексту настоящего Соглашения, если явно не указано иное, под
                        термином «правоприменительное законодательство» понимается как
                        законодательство Республики Казахстан, так и законодательство места
                        пребывания Пользователя/Пользователей.
                    </p>

                    <p>
                        11.7. Признание судом какого-либо положения Соглашения недействительным или
                        не подлежащим принудительному исполнению не влечет недействительности или
                        неисполнимости иных положений данного Соглашения.{" "}
                    </p>
                </TextPageContent>
            </Container>
        </Page>
    )
}

export default TermsOfUsePage
