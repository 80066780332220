import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Modal from "modules/components/Modal/Modal"
import Success from "modules/components/Success/Success"
import { Button } from "ui/Button/Button.styled"

interface CreatedModal {
    postID: number
}

const StyledCreatedModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 4rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radius.small};
`

const CreatedModal: React.FC<CreatedModal> = ({ postID }) => {
    const navigate = useNavigate()

    return (
        <Modal>
            <StyledCreatedModal>
                <Success caption="Объявление опубликовано" />
                <Button onClick={() => navigate(`/catalogue/post/${postID}`)}>
                    Посмотреть объявление
                </Button>
            </StyledCreatedModal>
        </Modal>
    )
}

export default CreatedModal
